<template>
	<section class="sandbox">
		<div class="sandbox__inner">
			<div class="sandbox__column" ref="resizeLeft" v-show="isShowLeftCol">
				<div class="sandbox__row mb-12">
					<nav class="sandbox__breadcrumbs breadcrumbs">
						<ol class="breadcrumbs__list">
							<li class="breadcrumbs__item">
								<a href="/">Главная</a>
							</li>
							<li class="breadcrumbs__item">
								<a :href="course_url">{{ course_name }}</a>
							</li>
							<li v-if="currentTask" class="breadcrumbs__item">
								{{ currentTask.data.title }}
							</li>
						</ol>
					</nav>
					<div class="ml-auto">
						<a class="icon-btn icon-btn--home" href="/">
							<span class="icon-btn__title">Вернуться&nbsp;на&nbsp;главную</span>
						</a>
					</div>
				</div>

				<div class="sandbox__content">
					<section class="sandbox__task sandbox-task scrollable">
						<template v-if="currentTask">
							<div v-show="currentView === 'task'">
								<h1>{{ currentTask.data.title }}</h1>
								<div v-html="currentTask.data.text"></div>
							</div>
							<div v-show="currentView === 'hints'">
								<h1>{{ currentTask.data.title }}</h1>
								<p>Используйте подсказки, если у вас отсутствуют варианты решения.</p>
								<template v-if="hints">
									<div
										v-for="(hint, index) in hints"
										:key="index"
										class="mb-10"
										:class="{ 'is-open': hint.isRevealed }"
									>

										<h3>Подсказка {{ index + 1 }}</h3>
										<button
											v-if="!hint.isRevealed"
											@click="hint.isRevealed = !hint.isRevealed"
											class="small-btn action-btn"
										>
											Открыть подсказку {{ index + 1 }}
										</button>
										<div class="mt-5" :class="{'hide': !hint.isRevealed }" v-html="hint.text">
										</div>
									</div>
								</template>
							</div>
							<div class="sandbox-solution" v-show="currentView === 'solution'">
								<h1>{{ currentTask.data.title }}</h1>
								<p>Постарайтесь решить задачу самостоятельно, а потом сравните свое решение с нашим.</p>
								<button
									v-if="!solutionRevealed"
									@click="revealSolution"
									class="small-btn action-btn"
								>
									Открыть решение
								</button>
								<div
									:class="{ 'hide': !solutionRevealed }"
									v-html="solution"
								/>
							</div>
						</template>
						<Loader v-else class="sandbox-result__loader" />
					</section>
				</div>

				<div class="sandbox__row mt-12">
					<button
						@click="setView('task')"
						class="sandbox-btn sandbox-btn--icon sandbox-btn--task"
						:class="{ 'is-active': currentView === 'task' }"
						type="button"
					>
						Задача
					</button>
					<template v-if="hints.length">
						<div v-if="!(localStoredData.isHints || taskCompleted)" class="sandbox-btn-holder ml-12">
							<span class="sandbox-btn sandbox-btn--icon sandbox-btn--hint disabled">Подсказки</span>
							<div v-if="!(localStoredData.isHints || taskCompleted)" class="sandbox-btn-holder__title">
								Станет доступным после первой проверки
							</div>
						</div>
						<button v-else @click="setView('hints')" class="sandbox-btn sandbox-btn--icon sandbox-btn--hint ml-12" :class="{ 'is-active': currentView === 'hints'}" type="button">
							Подсказки
						</button>
					</template>

					<template v-if="solution.length">
						<div v-if="!(localStoredData.isSolution || taskCompleted)" class="sandbox-btn-holder ml-12">
							<span class="sandbox-btn sandbox-btn--icon sandbox-btn--solution disabled">Решение</span>
							<div v-if="!(localStoredData.isSolution || taskCompleted)" class="sandbox-btn-holder__title">
								Станет доступным после двух проверок
							</div>
						</div>
						<button v-else @click="setView('solution')" class="sandbox-btn sandbox-btn--icon sandbox-btn--solution ml-12" :class="{ 'is-active': currentView === 'solution' }" type="button">
							Решение
						</button>
					</template>

					<div v-if="showPagination" class="page-nav ml-auto">
						<a
							class="page-nav__prev"
							:class="{ 'disabled': !prev_lecture_url }"
							:href="prev_lecture_url"
						>
							<span class="visually-hidden">Предыдущее задание</span>
						</a>
						<div class="page-nav__numbers">{{ lecture_position }}&#8239;/&#8239;{{ count_lectures }}</div>
						<a
							class="page-nav__next"
							:class="{ 'disabled': !next_lecture_url }"
							:href="next_lecture_url"
						>
							<span class="visually-hidden">Следующее задание</span>
						</a>
					</div>
				</div>
			</div>

			<button v-show="isShowLeftCol" @mousedown="setResizeCols" @dblclick="resetPositionCols" class="sandbox__resize-btn resize-btn resize-btn--column" type="button">
				<span class="visually-hidden">Изменить размер окон</span>
			</button>

			<div class="sandbox__column" ref="resizeRight">
				<div class="sandbox__row mb-12">
					<span class="sandbox-btn mr-25" v-if="typeExercise === 'sql'">
						PostgreSQL 13.1
					</span>
					<span class="sandbox-btn mr-25" v-else-if="typeExercise === 'python'">
						Python 3.9.2
					</span>
					<button
						@click="clearCode"
						class="icon-btn icon-btn--remove mr-25"
						:class="{ 'is-active': isActiveClearBtn, 'disabled': !isActiveClearBtn }"
						type="button"
					>
						<span class="icon-btn__title">Сбросить</span>
					</button>
					<button
						@click="resetCode"
						class="icon-btn icon-btn--reset"
						:class="{ 'is-active': isActiveResetBtn, 'disabled': !isActiveResetBtn }"
						type="button"
					>
						<span class="icon-btn__title">Вернуться&nbsp;к&nbsp;последнему варианту&nbsp;решения</span>
					</button>
					<div class="sandbox-popover ml-auto" :class="{ 'is-open': isOpenCommands }" ref="popover">
						<button
							@click="showCommands"
							class="icon-btn icon-btn--comands sandbox-popover__btn mr-25"
							type="button"
						>
							<span class="icon-btn__title">Быстрые&nbsp;команды</span>
						</button>
						<div class="sandbox-popover__panel">
							<button @click="showCommands" class="sandbox-popover__remove-btn remove-btn remove-btn--tiny" type="button">
								<span class="visually-hidden">Закрыть</span>
							</button>
							<p class="bold-font">Быстрые команды:</p>
							<p>Выполнить — <span class="monospace">Alt + Enter</span></p>
							<p>Проверить — <span class="monospace">Ctrl/Cmd + Enter</span></p>
							<p>Отмена — <span class="monospace">Ctrl/Cmd + Z</span></p>
							<p>Закомментировать — <span class="monospace">Ctrl/Cmd + /</span></p>
						</div>
					</div>
					<button
						@click="showLeftCol"
						class="icon-btn icon-btn--resize"
						:class="{ 'is-open': !isShowLeftCol }"
						type="button"
					>
						<span class="icon-btn__title">
							{{ isShowLeftCol ? 'Развернуть&nbsp;редактор' : 'Свернуть&nbsp;редактор' }}
						</span>
					</button>
				</div>
				<div class="sandbox__content">
					<section class="sandbox__editor sandbox-editor scrollable" ref="resizeTop">
						<h2 class="visually-hidden">Редактор</h2>
						<v-ace-editor
							v-model:value="sessionStoredData.currentCode"
							:placeholder="placeholderEditor"
							:lang="this.typeExercise"
							theme="chrome"
							style="height: 100%; width: 100%; font-size: 13.5px; line-height: 19px;"
						/>
					</section>

					<button @mousedown="setResizeRows" @dblclick="resetPositionRows" class="sandbox__resize-btn resize-btn" type="button">
						<span class="visually-hidden">Изменить размер окон</span>
					</button>

					<section class="sandbox__result sandbox-result" ref="resizeBottom">
						<div class="sandbox-result__top">
							<h2
								class="sandbox-result__title"
								:class="{
									'sandbox-result__title--check': isCorrectAnswer,
									'sandbox-result__title--error': isWrongAnswer,
								}"
							>
								Терминал
							</h2>
						</div>
						<div class="sandbox-result__scrollable scrollable">
							<template v-if="isCorrectAnswer">
								<p>Верно! <template v-if="next_lecture_url">Переходите к следующему заданию.</template></p>
							</template>

							<template v-if="isWrongAnswer">
								<p v-if="hints.length">Неверно. Проверьте код или воспользуйтесь подсказкой.</p>
								<p v-else>Неверно. Проверьте код.</p>
							</template>

							<template v-if="typeExercise === 'sql'">
								<template v-if="result">
									<table class="sandbox-table">
										<thead>
											<tr>
												<th></th>
												<th v-for="(field, index) in result.result.fields" :key="index">
													{{ field }}
												</th>
											</tr>
										</thead>
									<tbody>
										<tr v-for="(valueRow, index) in result.result.values" :key="index">
											<th>
												{{ index + 1 }}
											</th>
											<td v-for="(value, valueIndex) in valueRow" :key="valueIndex">
												{{ value }}
											</td>
										</tr>
										</tbody>
									</table>
								</template>
								<template v-if="error">
									<p class="bold-font">Ошибка:</p>
									<p class="monospace">{{ error }}</p>
								</template>
							</template>

							<template v-if="typeExercise === 'python'">
								<template v-if="isRunResult">
									<template v-if="result">
										<p class="monospace">{{ result.result }}</p>
										<p>Время выполнения: <span class="monospace">{{ result.time.toFixed(3) }} сек.</span></p>
									</template>
									<template v-if="error">
										<p class="bold-font">Ошибка:</p>
										<p class="monospace">{{ error }}</p>
									</template>
								</template>

								<template v-if="isCheckResult">
									<template v-if="result">
										<p v-if="!taskChecked">Не все тесты пройдены, есть ошибки.</p>
										<p>Время выполнения: <span class="monospace">{{ result.time.toFixed(3) }} сек.</span></p>
										<div v-if="result.summary" class="sandbox-result__stat">
											<h3>Общая статистика</h3>
											<p class="monospace">Всего тестов: {{ result.summary.total }}. Не пройдено: {{ result.summary.failed || 0 }}. Пройдено: {{ result.summary.passed || 0}}.</p>
											<p>Подробную информацию по каждому тесту смотрите ниже.</p>
										</div>

										<div class="sandbox-result__tests">
											<template v-for="(test, index) in result.tests">
												<h3>Тест {{ index + 1 }}</h3>
												<p>
													{{ test.status }}
													<span v-if="!test.errorDetails" class="green-font-color">✔</span>
													<span v-else class="red-font-color">✗</span>
												</p>

												<p class="bold-font">Формулировка:</p>
												<template v-if="test.testDescription">
													<p>{{ test.testDescription }}</p>
												</template>

												<p>Имя проверяемого метода/функции: <code>{{ test.testName }}</code></p>

												<template v-if="test.testInputData">
													<p>Аргумент для проверки:</p>
													<pre>
														<code>{{ test.testInputData }}</code>
													</pre>
												</template>

												<template v-if="test.errorDetails">
													<p class="bold-font">Ошибка:</p>
													<p>{{ test.errorDetails }}</p>
												</template>
											</template>
										</div>
									</template>
								</template>
							</template>
						</div>

						<Loader v-if="loading" class="loader--middle-bg sandbox-result__loader" />
					</section>
				</div>

				<div class="sandbox__row mt-12">
					<span v-if="!taskCompleted" class="sandbox-btn mr-25">
						Не решено
					</span>
					<span v-else class="sandbox-btn sandbox-btn--completed mr-25">
						<span class="icon icon--regular icon--check icon--high-color">Решено</span>
					</span>

					<div class="ml-auto">
						<button
							@click="runCode"
							@keyup.shift.enter="runCode"
							class="sandbox-btn sandbox-btn--action mr-12"
							:class="{ 'disabled': disabledRunBtn }"
							type="button"
						>
							Выполнить
						</button>

						<button
							@click="checkCode"
							class="sandbox-btn sandbox-btn--start"
							:class="{ 'disabled': disabledCheckBtn }"
							type="button"
						>
							Проверить
						</button>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { VAceEditor } from "vue3-ace-editor";
import axios from "axios";
import hljs from 'highlight.js';
import Loader from '../shared/Loader.vue';

import ace from 'ace-builds';
import modeSqlUrl from 'ace-builds/src-noconflict/mode-sql?url';
ace.config.setModuleUrl('ace/mode/sql', modeSqlUrl);
import modePythonUrl from 'ace-builds/src-noconflict/mode-python?url';
ace.config.setModuleUrl('ace/mode/python', modePythonUrl);
import themeChromeUrl from 'ace-builds/src-noconflict/theme-chrome?url';
ace.config.setModuleUrl('ace/theme/chrome', themeChromeUrl);
// для добавления других языков https://github.com/CarterLi/vue3-ace-editor/issues/9

export default {
	name: 'Sandbox',

	components: {
		VAceEditor,
		Loader
	},

	props: {
		playground_exercise_id: { type: String },
		attempts_url: { type: String },
		user_lecture_record_id: { type: String },
		playground_complete_url: { type: String },
		course_url: { type: String },
		course_name: { type: String },
		next_lecture_url: { type: String, default: '' },
		prev_lecture_url: { type: String, default: '' },
		count_lectures: { type: String, default: 1 },
		lecture_position: { type: String, default: 1 },
		lecture_completed: { type: String },
		last_attempts_code: { type: String },
		playground_base_url: { type: String }
	},

	data() {
		return {
			typeExercise: 'sql',
			currentTask: null,
			currentView: 'task',
			result: null,
			error: null,
			isCheckResult: false,
			attempts: [],
			courseCompleted: false,
			solutionRevealed: false,
			isRunResult: false,
			taskChecked: false,
			taskCompleted: this.lecture_completed === 'true',
			hints: [],
			solution: '',
			isShowLeftCol: true,
			isOpenCommands: false,
			lastCheckCode: null,
			lastRunCode: null,
			position: {
				x: 0,
				y: 0
			},
			isResizing: {
				col: false,
				row: false
			},
			minSize: {
				width: 400,
				height: 43
			},
			loading: false,
			localStoredData: {
				id: this.playground_exercise_id,
				isHints: false,
				isSolution: false,
			},
			sessionStoredData: {
				id: this.playground_exercise_id,
				currentCode: this.last_attempts_code || '',
			},
		}
	},

	updated() {
	this.$nextTick(() => {
		if (window.MathJax) {
			MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
		}
	});
	},

	mounted() {
		axios.get(`${this.playground_base_url}${this.playground_exercise_id}`)
			.then(response => {
				this.currentTask = response.data
				this.hints = this.currentTask.data.hints.map(hint => ({ text: hint.text, isRevealed: false }));
				this.solution = this.currentTask.data.solution;
				this.typeExercise = this.currentTask.data.type
			})
			.catch(error => {
				console.error(error)
			})

		document.addEventListener("mouseup", this.endResizeCols);
		document.addEventListener("mouseup", this.endResizeRows);
		document.addEventListener("mousemove", this.resizingCols);
		document.addEventListener("mousemove", this.resizingRows);

		document.addEventListener('keyup', this.handleKeyUpRun);
		document.addEventListener('keyup', this.handleKeyUpCheck);
		document.addEventListener('keyup', this.handleEscEvent);

		document.addEventListener('click', this.clickOutside);
		window.addEventListener('load', this.highlightCode);

		this.setStoredData(localStorage.lectures, 'localStoredData');
		this.setStoredData(sessionStorage.lectures, 'sessionStoredData');

		this.$nextTick(() => {
			if (window.MathJax) {
				MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
			}
		})
	},

	beforeDestroy() {
		document.removeEventListener('keyup', this.handleKeyUpRun);
		document.removeEventListener('keyup', this.handleKeyUpCheck);
		document.removeEventListener('keyup', this.handleEscEvent);
		document.removeEventListener('click', this.clickOutside);
	},

	watch: {
		localStoredData: {
			deep: true,
			handler(newValue, oldValue) {
				if(!this.taskCompleted) {
					this.updateLocalStoredData();
				}
			}
		},

		sessionStoredData: {
			deep: true,
			handler(newValue, oldValue) {
				this.updateSessionStoredData();
			}
		},
	},

	computed: {
		showPagination() {
			return this.count_lectures > 1;
		},

		isActiveClearBtn() {
			return this.sessionStoredData.currentCode;
		},

		isActiveResetBtn() {
			return (
				this.lastCheckCode &&
				this.sessionStoredData.currentCode !== this.lastCheckCode
			);
		},

		disabledRunBtn() {
			return this.sessionStoredData.currentCode.trim() === '';
		},

		disabledCheckBtn() {
			return !(
				this.isRunResult &&
				!this.error &&
				(
					(this.lastRunCode !== this.lastCheckCode && this.sessionStoredData.currentCode.trim() === this.lastRunCode) ||
					(this.lastRunCode === this.lastCheckCode && this.sessionStoredData.currentCode.trim() === this.lastRunCode)
				)
			);
		},

		isCorrectAnswer() {
			return this.isCheckResult && this.taskChecked;
		},

		isWrongAnswer() {
			return this.isCheckResult && !this.taskChecked;
		},

		placeholderEditor: function() {
			if (this.typeExercise === 'sql') {
				return 'Введите свое решение. Вы работаете с PostgreSQL.';
			}

			if (this.typeExercise === 'python') {
				return 'Введите свое решение. Вы работаете с Python.';
			}
		},
	},

	methods: {
		findIndex(store) {
			return store.findIndex(obj => obj.id === this.playground_exercise_id);
		},

		setStoredData: function(data, currentData) {
			if (data) {
				let store = JSON.parse(data);
				let index = this.findIndex(store);
				if (index !== -1) {
					this[currentData] = store[index];
				}
			}
		},

		updateLocalStoredData() {
			if (!localStorage.lectures) {
				localStorage.lectures = '[' + JSON.stringify(this.localStoredData) + ']';
				return;
			}

			let store = JSON.parse(localStorage.lectures);
			let index = this.findIndex(store);
			if (index !== -1) {
				store[index] = this.localStoredData;
			} else {
				store.push(this.localStoredData);
			}

			localStorage.lectures = JSON.stringify(store);
		},

		removeLocalStoredData() {
			if (!localStorage.lectures) {
				return;
			}

			let store = JSON.parse(localStorage.lectures);
			let index = this.findIndex(store);
			if (index !== -1) {
				store.splice(index, 1);
			}
			store.length ?
				localStorage.lectures = JSON.stringify(store) :
				localStorage.removeItem('lectures');
		},

		updateSessionStoredData() {
			if (!sessionStorage.lectures) {
				sessionStorage.lectures = '[' + JSON.stringify(this.sessionStoredData) + ']';
				return;
			}

			let store = JSON.parse(sessionStorage.lectures);
			let index = this.findIndex(store);
			if (index !== -1) {
				store[index] = this.sessionStoredData;
			} else {
				store.push(this.sessionStoredData);
			}

			sessionStorage.lectures = JSON.stringify(store);
		},

		getRunResults() {
			axios.post(`${this.playground_base_url}${this.playground_exercise_id}/run`, {
				code: this.sessionStoredData.currentCode.trim()
			})
				.then(response => {
					if (response.data.success) {
						this.result = response.data;
						this.error = null;
					} else {
						this.result = null;
						this.error = response.data.error;
					}
					this.loading = false;
				})
				.catch(error => {
					console.error(error)
				})
		},

		getCheckResults() {
			axios.post(`${this.playground_base_url}${this.playground_exercise_id}/check`, {
				code: this.sessionStoredData.currentCode.trim()
			})
			.then(response => {
				this.loading = false;
				this.saveAttempt(response.data.success);
				this.result = response.data.run_result;

				if (response.data.success) {
					if (!this.taskCompleted) {
						this.completePlayground();
						this.removeLocalStoredData();
					}
					this.error = null;
					this.taskCompleted = true;
					this.taskChecked = true;
				} else {
					this.error = response.data.run_result.error;
					this.taskChecked = false;
				}
			})
			.catch(error => {
				console.error(error);
			})
		},

		saveAttempt(success) {
			axios.request({
				headers: {
					"X-Requested-With": "XMLHttpRequest",
					"X-CSRF-Token": document
						.querySelector('meta[name="csrf-token"]')
						.getAttribute("content"),
				},
				responseType: "json",
				url: this.attempts_url,
				method: "POST",
				data: {
					success: success,
					current_code: this.sessionStoredData.currentCode,
					user_lecture_record_id: this.user_lecture_record_id,
				}
			}).then((response) => {
			})
		},

		completePlayground() {
			axios.request({
				headers: {
					"X-Requested-With": "XMLHttpRequest",
					"X-CSRF-Token": document
						.querySelector('meta[name="csrf-token"]')
						.getAttribute("content"),
				},
				responseType: "json",
						url: this.playground_complete_url,
						method: "POST"
			}).then((response) => {
					this.courseCompleted = response.data.completed_course;
					if (this.courseCompleted) {
						window.location.href = `${this.course_url}?successfully_completed=1`
					}
					}).catch((error) => {
						console.error(error);
					})
		},

		runCode() {
			this.loading = true;
			this.isRunResult = true;
			this.isCheckResult = false;
			this.lastRunCode = this.sessionStoredData.currentCode.trim();
			this.getRunResults();
		},

		checkCode() {
			this.loading = true;
			this.isCheckResult = true;
			this.localStoredData.isHints ? this.localStoredData.isSolution = true : this.localStoredData.isHints = true;
			this.lastCheckCode = this.sessionStoredData.currentCode.trim();
			this.getCheckResults();
			this.isRunResult = false;
		},

		setView(view) {
			this.currentView = view;
		},

		revealSolution() {
			this.solutionRevealed = true;
		},

		clearCode() {
			return this.sessionStoredData.currentCode = '';
		},

		resetCode() {
			this.sessionStoredData.currentCode = this.lastCheckCode;
		},

		showCommands() {
			return this.isOpenCommands = !this.isOpenCommands;
		},

		showLeftCol() {
			return this.isShowLeftCol = !this.isShowLeftCol;
		},

		setResizeCols(e) {
			this.isResizing.col = true;
			this.position.x = e.pageX;
		},

		setResizeRows(e) {
			this.isResizing.row = true;
			this.position.y = e.pageY;
		},

		resetPositionCols() {
			this.position.x = this.startPosition.x;
		},

		resetPositionRows() {
			this.position.y = this.startPosition.y;
		},

		resizingCols(e) {
			if (!this.isResizing.col) {
				return;
			}

			const resizeLeft = this.$refs.resizeLeft;
			const resizeRight = this.$refs.resizeRight;
			const walk = e.pageX - this.position.x;
			let LeftWidth = resizeLeft.clientWidth + walk;
			let RightWidth = resizeRight.clientWidth - walk;

			if (LeftWidth <= this.minSize.width || RightWidth <= this.minSize.width) {
				this.isResizing.col = false;
				return;
			}

			resizeLeft.style.width = LeftWidth + "px";
			resizeRight.style.width = RightWidth + "px";

			this.position.x = e.pageX;
		},

		resizingRows(e) {
			if (!this.isResizing.row) {
				return;
			}

			const resizeTop = this.$refs.resizeTop;
			const resizeBottom = this.$refs.resizeBottom;
			const walk = e.pageY - this.position.y;
			let TopHeight = resizeTop.clientHeight + walk;
			let BottomHeight = resizeBottom.clientHeight - walk;

			if (TopHeight <= this.minSize.height || BottomHeight <= this.minSize.height) {
				this.isResizing.row = false;
				return;
			}

			resizeTop.style.height = TopHeight + "px";
			resizeBottom.style.height = BottomHeight + "px";

			this.position.y = e.pageY;
		},

		endResizeCols() {
			this.isResizing.col = false;
		},

		endResizeRows() {
			this.isResizing.row = false;
		},

		highlightCode() {
			document.querySelectorAll('.sandbox-solution pre code').forEach((el) => {
				hljs.highlightElement(el);
			});
		},

		handleKeyUpRun(e) {
			if (e.altKey && e.key === 'Enter' && !this.disabledRunBtn) {
				e.preventDefault();
				this.runCode();
			}
		},

		handleKeyUpCheck(e) {
			if (e.ctrlKey && e.key === 'Enter' & !this.disabledCheckBtn) {
				e.preventDefault();
				this.checkCode();
			}
		},

		handleEscEvent(e) {
			if (e.key === 'Escape') {
				e.preventDefault();
				this.isOpenCommands = false;
			}
		},

		clickOutside(e) {
			const popover = this.$refs.popover;
			const btn = popover.querySelector('sandbox-popover__btn');
			let target = e.target;
			let isPopover = target == popover || popover.contains(target);
			let isBtn = target == btn;
			let popoverIsOpen = popover.classList.contains('is-open');

			if (!isPopover && !isBtn && popoverIsOpen) {
				this.isOpenCommands = false;
			}
		}
	}
}
</script>
