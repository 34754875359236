<script>
import axios from "axios";

function parseDropdownValues(values) {
	var result = [];
	if (values.length > 0) {
		for (const value of values) {
			if (value !== undefined && value.id) {
				result.push(value.id);
			}
		}
	}
	return result;
}

export default {
	name: "ProgramsAssignmentForm",
	template: `<slot v-bind="self" />`,

	props: {
		open_preset_form: Boolean,
		default_assignee_ids: Array,
		default_taskable_ids: Array,
	},

	data() {
		return {
			formElement: null,
			assignment: {},
			form_action: {},
			datepickers: {},
			authenticity_token: "",
			errors: {},
			dateTabs: [
				{
					id: "date_range_day",
					title: I18n.t.assignment_form.date_tabs.day,
					count: 1,
					period: "day",
				},
				{
					id: "date_range_week",
					title: I18n.t.assignment_form.date_tabs.week,
					count: 1,
					period: "week",
				},
				{
					id: "date_range_month",
					title: I18n.t.assignment_form.date_tabs.month,
					count: 1,
					period: "month",
				},
				{
					id: "date_range_custom",
					title: I18n.t.assignment_form.date_tabs.choosed_dates,
					count: 0,
					period: "custom",
				},
			],
			countDaysTabs: [
				{
					id: "count_days_day",
					title: I18n.t.assignment_form.date_tabs.day,
					count: 1,
				},
				{
					id: "count_days_week",
					title: I18n.t.assignment_form.date_tabs.week,
					count: 7,
				},
				{
					id: "count_days_month",
					title: I18n.t.assignment_form.date_tabs.month,
					count: 30,
				},
				{
					id: "count_days_custom",
					title: I18n.t.assignment_form.date_tabs.choose_term,
					count: null,
				},
			],
			t: I18n.t,
		};
	},

	computed: {
		self() {
			return this;
		},

		// computed
		currentDateTabMenu() {
			if (moment().isSame(this.assignment.date_from, "day")) {
				if (
					moment()
						.add(1, "day")
						.isSame(this.assignment.date_to, "day")
				) {
					return "date_range_day";
				} else if (
					moment()
						.add(1, "week")
						.isSame(this.assignment.date_to, "week")
				) {
					return "date_range_week";
				} else if (
					moment()
						.add(1, "month")
						.isSame(this.assignment.date_to, "month")
				) {
					return "date_range_month";
				} else {
					return "date_range_custom";
				}
			}
			return "date_range_custom";
		},

		// computed
		currentCountDaysTabMenu() {
			if (this.assignment.count_days == 1) {
				return "count_days_day";
			} else if (this.assignment.count_days == 7) {
				return "count_days_week";
			} else if (this.assignment.count_days == 30) {
				return "count_days_month";
			} else {
				return "count_days_custom";
			}
		},
	},

	methods: {
		submitAssignmentForm() {
			this.processingForm(this.form_action.method, this.form_action.url)
		},

		deleteAssignment(confirm_message) {
			var result = confirm(confirm_message);
			if (!result) {
				return result
			}

			this.processingForm('delete', `/${I18n.locale}/users/assignments/${this.assignment.id}`)
		},

		processingForm(method, url) {
			this.$parent.loadingOn();

			axios({
				response_type: "json",
				headers: {
					"X-Requested-With": "XMLHttpRequest",
					"X-CSRF-Token": document
						.querySelector('meta[name="csrf-token"]')
						.getAttribute("content"),
				},

				method: method,
				url: url,

				data: {
					utf8: this.utf8,
					authenticity_token: this.authenticity_token,
					users_prescription_item: this.assignment,
				},

				validateStatus: function(status) {
					return status >= 200 && status < 300; // default
				},
			})
				.then((response) => {
					this.errors = {};
					this.$parent.loadingOff();

					this.emitter.emit(
						"set_root_assignment_items",
						response.data.assignments
					);
					this.$parent.loadingOn().closePopup();
				})
				.catch((errors) => {
					this.$parent.loadingOff();
					this.errors = errors.response.data;
				});
		},

		setDropdownValue(dropdown) {
			console.log(dropdown.name);
			console.log(dropdown.values);
			if (!Array.isArray(dropdown.values)) {
				dropdown.tasks = [dropdown.values];
			}
			this[dropdown.name].clear_selected_values();
			for (var value of dropdown.values) {
				if (value) {
					this[dropdown.name].add_selected_values(value);
				}
			}
			this[dropdown.name].activate();
		},

		clearFormData() {
			this.assignment = {};
			this.setDropdownValue({name: "dropdownTaskable", values: []});
			this.setDropdownValue({name: "dropdownAssignee", values: []});
		},

		showNewAssignmentForm(url) {
			this.clearFormData();
			this.$parent.loadingOn().openPopup();

			axios
				.request({
					response_type: "json",
					headers: { "X-Requested-With": "XMLHttpRequest" },
					url: url,
				})
				.then((response) => {
					this.assignment = response.data.assignment;
					this.form_action = response.data.form_action;

					if (!this.assignment.assignee_ids.length) {
						this.assignment.assignee_ids =
							this.assignment.assignee_ids + this.default_assignee_ids;
					}

					if (!this.assignment.taskable_ids.length) {
						this.assignment.taskable_ids =
							this.assignment.taskable_ids + this.default_taskable_ids;
					}

					this.setDropdownValue({
						name: "dropdownTaskable",
						values: this.assignment.taskable_ids
					});
					this.setDropdownValue({
						name: "dropdownAssignee",
						values: this.assignment.assignee_ids
					});

					this.authenticity_token = this.formElement.querySelector(
						'input[name="authenticity_token"]'
					).value;

					this.$parent.loadingOff();
				});
		},

		showPresetsPopup() {
			this.emitter.emit("show_presets_popup");
		},

		setAssignmentName(preset_name) {
			this.assignment.name = preset_name;
		},

		setAssignmentPresetId(preset_id) {
			this.assignment.preset_id = preset_id;
		},

		autoOpenPresetsForm() {
			this.$nextTick(function() {
				this.showPresetsPopup();
			});
		},

		isFixed() {
			return this.assignment.prescription_item_type == "fixed";
		},

		isDynamic() {
			return this.assignment.prescription_item_type == "dynamic";
		},

		setDate(count, period) {
			this.assignment.date_from = moment().format("YYYY-MM-DD");
			this.assignment.date_to = moment().add(parseInt(count), period).format("YYYY-MM-DD");
		},

		setCountDays(count) {
			this.assignment.count_days = count;
		},

		initializeDropdowns() {
			this.dropdownTaskable = new Dropdown(
				document.querySelector(
					"div[data-dropdown-name='j-dropdown-taskable_ids']"
				),
				(values) => {
					let selectedValues = parseDropdownValues(values);
					this.assignment.taskable_ids = selectedValues;
				}
			);

			this.dropdownAssignee = new Dropdown(
				document.querySelector(
					"div[data-dropdown-name='j-dropdown-assignee_ids']"
				),
				(values) => {
					let selectedValues = parseDropdownValues(values);
					this.assignment.assignee_ids = selectedValues;
				}
			);
		},

		initializeDatepickers() {
			this.formElement
				.querySelectorAll(".j-datepicker")
				.forEach((datepicker) => {
					let options = {
						field: datepicker,
						theme: "pikaday-theme",
						firstDay:
							1 *
							moment()
								.startOf("week")
								.format("E"),
						i18n: {
							months: _.map(moment.months(), capitalize),
							weekdays: _.map(moment.weekdays(), capitalize),
							weekdaysShort: _.map(moment.weekdaysShort(), capitalize),
						},
						onSelect: (date) => {
							this.assignment[datepicker.dataset.model] = moment(date).format(
								"YYYY-MM-DD"
							);
						},
					};

					if (I18n.locale == "ru") {
						_.extend(options.i18n, {
							previousMonth: "Предыдущий месяц",
							nextMonth: "Следующий месяц",
						});
					}

					this.datepickers[datepicker.dataset.model] = new Pikaday(options);
				});
		},

		closePopup() {
			this.$parent.closePopup();
		},
	},

	mounted() {
		this.formElement = document.querySelector('.j-program-assignment-form-wrap form');
		console.log(this.emitter)
		this.initializeDropdowns();
		this.initializeDatepickers();

		this.emitter.on("show_assignment_form", this.showNewAssignmentForm);
		this.emitter.on("tasks_for_assignment_form", this.setDropdownValue);
		this.emitter.on("name_for_assignment_form", this.setAssignmentName);
		this.emitter.on("id_for_assignment_form", this.setAssignmentPresetId);

		if (this.open_preset_form) this.autoOpenPresetsForm();
	},
};
</script>
