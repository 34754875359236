<template>
	<div class="catalog">
		<div v-if="current_company !== 'demo-courses'" class="catalog__header catalog__header--bg-action">
			<h1 class="catalog__title">{{ $t('presets') }}</h1>
		</div>
		<section class="catalog__section">
			<div class="catalog__section-header">
				<h2 class="catalog__title">
					{{ $t('buttons.all_presets') }}
					<span class="quantity">{{ presets.length }}</span>
				</h2>
			</div>
			<div class="catalog__image-list">
				<div
					class="catalog__image-item"
					v-for="(preset, index) in presets"
					:key="'preset-' + preset.id + '-' + index"
				>
					<div class="image-item">
						<a :href="'/presets/' + preset.id" class="image-item__link">
							<img
								:src="preset.picture_url"
								:alt="preset.name"
								class="image-item__img"
								loading="lazy"
							/>
						</a>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	name: "CoursesPresets",

	props: {
		presets: Array,
		preset: Array,
		current_company: {
			type: String,
			default: ''
		}
	},
};
</script>
