<script>
export default {
	name: 'QuizChoiceAnswer',
	template: `<slot v-bind="self" />`,

	props: {
		quiz_answers: Array,
	},

	data() {
		return {
			answers: this.quiz_answers.sort((a, b) => a.pos - b.pos)
		}
	},

	mounted() {
		console.log('QuizChoiceAnswer mounted')
	},

	computed: {
		self() {
			return this;
		},

		allCorrectAnswers() {
			return (
				this.answers.length &&
				this.answers.map(answer => { return answer.correct }).every(element => element === true)
			)
		},
	},

	methods: {
		createBlankAnswer() {
			var answer = {
				"body": null,
				"correct": null,
				"pos": this.answers.length+1,
				"secondary_body":null
			}
			this.answers.push(answer)
		},

		deleteAnswer(answer) {
			this.answers.splice(this.answers.indexOf(answer), 1)
		},
	}
}
</script>
