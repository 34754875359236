<template>
	<template v-if="diplomas.length">
		<div class="flex-basic-container align-center mb-20 no-print">
			<label class="checkbox mr-20">
				<input class="checkbox__input" id="only-with-diploma" name="" type="checkbox">
				<span class="checkbox__label">{{ $t('with_diploma') }}</span>
			</label>

			<div class="courses-filters">
				<div class="courses-filters__group courses-filters__group--rbc">
					<button class="courses-filters__btn" type="button">{{ $t('filters') }}</button>
					<div class="courses-filters__holder">
						<h3 class="courses-filters__title">{{ $t('show_only') }}</h3>
						<ul class="courses-filters__list">
							<li
								v-for="(filter, index) in courseTypesFilter"
								:key="'filter-' + index"
								class="courses-filters__item"
							>
								<label class="checkbox">
									<input
										type="checkbox"
										class="visually-hidden"
										:value="filter.type"
										name
										v-model="checkedTypesFilterCompletedCourses"
									/>
									<span class="checkbox__label">
										{{ $t(`course_types.${filter.type}`) }}
									</span>
								</label>
							</li>
						</ul>
						<button @click="filterClear" class="regular-btn action-btn mt-15" :class="{ 'disabled': !checkedTypesFilterCompletedCourses.length }">
							{{ $t('buttons.clear_filters') }}
						</button>
					</div>
				</div>
			</div>
		</div>

		<template v-if="filteredCourses.length">
			<template v-for="diploma in filteredCourses" :key="'diploma-' + diploma.id">
				<CompletedCourseItem :course="diploma" firstName="first_name" />
			</template>
		</template>
		<p v-else>
			{{ $t('messages.no_filters_message') }}
		</p>
	</template>

	<p v-else class="page-card__section">{{ $t('messages.no_diplomas_message') }}</p>
</template>

<script>
import CompletedCourseItem from './CompletedCourseItem.vue';

export default {
	name: 'UserCompletedCourses',

	props: {
		diplomas: { type: Array },
		first_name: {
			type: String,
			default: ''
		}
	},

	components: {
		CompletedCourseItem
	},

	data() {
		return {
			loading: true,
			showFiltres: false,
			sort: false,
			checkedTypesFilterCompletedCourses: [],
			courseTypesFilter: [
				{ title: "Видеокурсы", type: "long" },
				{ title: "Мини-курсы", type: "short" },
				{ title: "Инт. презентация", type: "presentation" },
				{ title: "Бизнес-кейсы", type: "business_case" },
				{ title: "Тренажер", type: "audition" },
				{ title: "Практическое задание", type: "practical_task" }
			],
		}
	},

	mounted() {
		if (localStorage.checkedTypesFilterCompletedCourses) {
			this.checkedTypesFilterCompletedCourses = localStorage.checkedTypesFilterCompletedCourses.split(',');
		}
	},

	watch: {
		checkedTypesFilterCompletedCourses(newValue) {
			localStorage.checkedTypesFilterCompletedCourses = newValue;
		}
	},

	computed: {
		filteredCourses() {
		return this.checkedTypesFilterCompletedCourses.length != 0
			? this.diplomas.filter((diploma) =>
				this.checkedTypesFilterCompletedCourses.includes(diploma.course_type)
			)
			: this.diplomas;
		},
	},

	methods: {
		filterClear() {
			this.checkedTypesFilterCompletedCourses = [];
		}
	},
}
</script>
