<template>
	<video ref="videoPlayer" class="video-js"></video>
</template>

<script>
import videojs from "video.js";
import axios from "axios";
import 'video.js/dist/video-js.min.css';

export default {
	name: "VideoPlayer",
	props: {
		initialVideoOptions: {
			type: Object,
			default() {
				return {};
			},
		},
		initialVideoData: {
			type: Object,
			default() {
				return {};
			},
		},
	},

	data() {
		return {
			player: null,
			options: this.initialVideoOptions,
			videoData: this.initialVideoData,
			last_saved_position: null,
			sent_watch: null,
		};
	},

	watch: {
		initialVideoOptions: function (val) {
			this.player.src(val.sources);
		},
		initialVideoData: function (val) {
			this.videoData = val;
			this.last_saved_position = val.start_position;
			this.sent_watch = null;
			if (val.start_position > 0) {
				this.player.currentTime(val.start_position);
			}
		},
	},

	mounted() {
		this.player = videojs(this.$refs.videoPlayer, this.options);
		this.player.on("ready", this.onPlayerReady);
		this.player.on("timeupdate", this.onPlayerTimeUpdate);
		this.player.on("ended", this.onPlayerEnded);
	},

	beforeDestroy() {
		if (this.player) {
			this.player.dispose();
		}
	},

	methods: {
		onPlayerReady() {
			this.player.currentTime(this.videoData.start_position);
		},

		onPlayerTimeUpdate() {
			let position = this.player.currentTime();
			let end_pos = this.videoData.end_pos || this.player.duration() * 0.9;
			let rounded_position = Math.floor(position / 10) * 10;
			if (
				rounded_position > 0 &&
				position < end_pos &&
				this.last_saved_position != rounded_position
			) {
				this.last_saved_position = rounded_position;
				axios({
					response_type: "json",
					headers: {
						"X-Requested-With": "XMLHttpRequest",
						"X-CSRF-Token": document
							.querySelector('meta[name="csrf-token"]')
							.getAttribute("content"),
					},

					url: this.videoData.save_video_position_path,
					method: "POST",

					data: {
						utf8: this.utf8,
						saved_video_position: rounded_position,
					},
				})
					.then((response) => {
						this.videoData.start_position = rounded_position;
						this.emitter.emit("update_video", this.videoData);
					})
					.catch((errors) => {
						console.warn(errors);
					});
			}

			if (position > end_pos && !this.sent_watch) {
				axios({
					response_type: "json",
					headers: {
						"X-Requested-With": "XMLHttpRequest",
						"X-CSRF-Token": document
							.querySelector('meta[name="csrf-token"]')
							.getAttribute("content"),
					},

					url: this.videoData.watch_video_path,
					method: "POST",
				}).then((response) => {
					this.emitter.emit("update_current_lecture");
					this.sent_watch = true;
				});
			}
		},

		onPlayerEnded() {
			this.emitter.emit("next_video");
		},
	},
};
</script>
