<template>
	<div class="catalog">
		<div class="catalog__header catalog__header--with-link">
			<h1 class="catalog__title catalog__title--play-list">
				{{ topic.title }}
			</h1>
			<p class="catalog__right">
				<a :href="'/' + topic.url" class="link-btn link-btn--light-font mt-5">Вернуться к списку курсов</a>
			</p>
		</div>

		<div class="catalog__section catalog__section--row catalog__section--no-padding-top">
			<section class="catalog__preview">
				<div class="catalog__video mb-10">
					<video-player
						:initialVideoOptions="videoOptions"
						:initialVideoData="currentLecture.video_params"
					/>
				</div>
				<h2 class="catalog__preview-title">{{ currentLecture.name }}</h2>
				<p
					v-if="currentCourse.lectures.length > 1"
					class="catalog__preview-descr"
				>
					Из курса
					<a :href="'/courses/' + currentCourse.id">{{ currentCourse.name }}</a>
				</p>
			</section>

			<section class="play-list catalog__play-list">
				<div class="play-list__header">
					<h2 class="play-list__header-title">Плейлист</h2>
					<p class="play-list__header-info">
						Просмотрено {{ watchedVideosCount }} из
						{{ videos_data.length }}
					</p>
				</div>
				<div class="play-list__content">
					<section
						v-for="(course, courseIndex) in courses"
						:key="'play-list-course-' + course.id + '-' + courseIndex"
						class="play-list__course"
					>
						<div class="play-list__course-header">
							<div class="play-list__image-holder">
								<img :src="course.logo_url" class="play-list__image" />
							</div>
							<h3 class="play-list__course-title">
								{{ course.name }}
							</h3>
						</div>
						<ol class="play-list__list">
							<li
								v-for="(lecture, lectureIndex) in course.lectures"
								:key="'play-list-program-item--' + lecture.id + '-' + lectureIndex"
								class="play-list__item"
								:class="{
									'play-list__item--is-checked': lecture.video_params && lecture.video_params.watched,
									'play-list__item--is-current': lecture.id == currentLecture.id,
								}"
							>
								<button class="play-list__btn" @click="changeCurrentLecture(courseIndex, lectureIndex)" type="button">
									<span class="play-list__num">{{ lecture.pos }}.</span>
									<h4 class="play-list__name">
										{{ lecture.name }}
									</h4>
									<p class="play-list__descr">{{ lecture.duration }} мин</p>
								</button>
							</li>
						</ol>
					</section>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import VideoPlayer from "../shared/VideoPlayer.vue";

export default {
	name: "CoursesPlayer",

	components: {
		VideoPlayer,
	},

	props: {
		topic: Object,
		videos_data: Array,
	},

	data() {
		return {
			currentCoursePos: 0,
			currentLecturePos: 0,
			videoIndex: 0,
			watchedVideosCount: this.videos_data.filter(
				(lecture) => lecture.watched == true
			).length,
			cs: null
		};
	},

	computed: {
		currentCourse() {
			return this.courses[this.currentCoursePos];
		},

		currentLecture() {
			return this.currentCourse.lectures[this.currentLecturePos];
		},

		courses() {
			let pos = 1;
			return this.topic.courses.map((course) =>
				Object.assign(
					course,
					course.lectures.map(
						(lecture) => (
							(lecture.pos = pos),
							(lecture.video_params = this.videos_data.find(
								(x) => x.lecture_id == lecture.id
							)),
							(pos = pos + 1)
						)
					)
				)
			);
		},

		videoOptions() {
			let options = {
				autoplay: true,
				controls: true,
				width: 610,
				height: 344,
				sources: [
					{
						src: this.currentLecture.video_url,
						type: "video/mp4",
					},
				],
				playbackRates: [0.5, 1, 1.25, 1.5, 2]
			};
			return options;
		},
	},

	methods: {
		updateCurrentVideo(video) {
			this.videos_data[this.videoIndex] = video;
		},

		updateCurrentLecture() {
			this.courses[this.currentCoursePos].lectures[
				this.currentLecturePos
			].video_params.watched = true;
			this.videos_data[this.videoIndex].watched = true;
			this.watchedVideosCount = this.videos_data.filter(
				(lecture) => lecture.watched == true
			).length;
			this.$forceUpdate();
		},

		nextVideo() {
			let firsUnwatchedCoursePos = this.courses.findIndex((course) =>
				course.lectures.find((lecture) => lecture.video_params && lecture.video_params.watched == null)
			);

			let nextUnwatchedCoursePos =
				this.courses
					.slice(this.currentCoursePos)
					.findIndex((course) =>
						course.lectures.find(
							(lecture) => lecture.video_params && lecture.video_params.watched == null
						)
					) + this.currentCoursePos;

			if (
				this.watchedVideosCount == this.videos_data.length &&
				this.currentCoursePos == this.courses.length - 1
			) {
				this.currentCoursePos = 0;
				this.currentLecturePos = 0;
			} else if (this.watchedVideosCount == this.videos_data.length) {
				if (this.currentCourse.lectures.length == this.currentLecturePos + 1) {
					this.currentCoursePos++;
					this.currentLecturePos = 0;
				} else {
					this.currentLecturePos++;
				}
			} else if (
				this.currentCoursePos == this.courses.length - 1 ||
				(firsUnwatchedCoursePos > -1 && nextUnwatchedCoursePos < 0)
			) {
				this.currentCoursePos = firsUnwatchedCoursePos;
				this.currentLecturePos = this.courses[
					this.currentCoursePos
				].lectures.findIndex((lecture) => lecture.video_params && lecture.video_params.watched == null);
			} else {
				this.currentCoursePos = nextUnwatchedCoursePos;
				this.currentLecturePos = this.courses[
					this.currentCoursePos
				].lectures.findIndex((lecture) => lecture.video_params && lecture.video_params.watched == null);
			}
			this.$forceUpdate();
		},

		changeCurrentLecture(coursePos, lecturePos) {
			this.currentCoursePos = coursePos;
			this.currentLecturePos = lecturePos;
			this.$forceUpdate();
		},
	},

	mounted() {
		let coursePos = this.courses.findIndex((course) =>
			course.lectures.find((lecture) => lecture.video_params && lecture.video_params.watched == null)
		);
		this.currentCoursePos = coursePos > -1 ? coursePos : 0;

		let lecturePos = this.courses[this.currentCoursePos].lectures.findIndex(
			(lecture) => lecture.video_params && lecture.video_params.watched == null
		);
		this.currentLecturePos = lecturePos > -1 ? lecturePos : 0;

		this.emitter.on("next_video", this.nextVideo);
		this.emitter.on("update_video", this.updateCurrentVideo);
		this.emitter.on("update_current_lecture", this.updateCurrentLecture);
	},
};
</script>
