<template>
	<article class="plan-item">
		<a class="plan-item__link" :href="prescription.url">
			<span class="visually-hidden">{{ $t('buttons.open_prescription') }}</span>
		</a>
		<div class="plan-item__header">
			<p>
				{{ prescription.program_title }}
			</p>
		</div>
		<h3 class="plan-item__title">
			{{ prescription.item_title }}
		</h3>
		<div v-if="!recommended" class="plan-item__footer">
			<p v-if="!recommended" class="plan-item__info">
				{{ currentDays(prescription) }} {{ daysLeft(prescription) }}
			</p>
			<p class="plan-item__progress-value">
				{{ $t('progress') }} {{ prescription.result }}%
			</p>
		</div>
		<div
			class="plan-item__progress"
			:class="plan_card_progress_class(prescription.result)"
			:style="{ height: prescription.result + '%' }"
		></div>
	</article>
</template>

<script>
export default {
	name: "PlanPreview",

	props: {
		prescription: Object,
		currentCompany: {
			type: String,
			default: ''
		},
		recommended: {
			type: Boolean,
			default: false
		},
	},

	data() {
		return {
			loading: true,
			isContinue: false,
		};
	},

	methods: {
		daysLeft(p) {
			if (p.how_many_days_left == 0 && p.result != 100) {
				this.isContinue = false;
				return this.$t('deadlines.late');
			} else if (p.result == 100) {
				this.isContinue = false;
				return this.$t('deadlines.finished');
			} else {
				this.isContinue = true;
				let days = +p.how_many_days_left;
				return this.$t('days', days);
			}
		},

		currentDays(p) {
			if (p.how_many_days_left == 0) {
				return this.$t('assignment');
			} else if (p.result == 100) {
				return this.$t('assignment');
			} else {
				return this.$t('deadlines.current');
			}
		},

		plan_card_progress_class(result) {
			if (result > 75) {
				return "plan-item__progress--high";
			} else if (result > 50) {
				return "plan-item__progress--upper-middle";
			} else if (result > 25) {
				return "plan-item__progress--lower-middle";
			} else {
				return "plan-item__progress--low";
			}
		},
	}
};
</script>
