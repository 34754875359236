<script>
import axios from 'axios';

export default {
	name: 'ProgramsOnboardingForm',
	template: '<slot v-bind="self" />',

	props: {
		program: { type: Object, required: true },
		durationUrl: { type: String, required: true }
	},

	data() {
		return {
			programName: this.program.name,
			onlyNewUsers: this.program.only_new_users,
			loading: true,
			accessibleToId: null,
			assigneeIds: [],
			weeks: {
				1: { taskableIds: [], duration: null },
				2: { taskableIds: [], duration: null },
				3: { taskableIds: [], duration: null },
				4: { taskableIds: [], duration: null },
			}
		}
	},

	mounted() {
		new Dropdown(document.querySelector("div[data-dropdown-name='j-accessible-dropdown']"), (value) => {
			this.accessibleToId = value[0] && value[0].id;
		})

		new Dropdown(document.querySelector("div[data-dropdown-name='j-dropdown-assignee_ids']"), (values) => {
			let selectedValues = this.parseDropdownValues(values);
			this.assigneeIds = selectedValues;
		});

		for (const weekNumber of [1,2,3,4]) {
			new Dropdown(document.querySelector("div[data-dropdown-name='j-dropdown-taskable_ids_" + weekNumber + "']"), (values) => {
				this.weeks[weekNumber].taskableIds = this.parseDropdownValues(values);
				this.setWeekDuration(weekNumber);
			})
		}
		this.loading = false
	},

	methods: {
		parseDropdownValues(values) {
			var result = []
			if (values.length > 0) {
				for (const value of values) {
					if (value !== undefined && value.id) { result.push(value.id) }
				}
			}
			return result;
		},

		setWeekDuration(weekNumber) {
			if (this.weeks[weekNumber].taskableIds.length == 0) {
				this.weeks[weekNumber].duration = null;
				return;
			}

			axios.request({
				response_type: 'json',
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				},
				method: 'POST',
				url: this.durationUrl,
				data: { taskables: this.weeks[weekNumber].taskableIds }
			}).then((response) => {
				this.weeks[weekNumber].duration = response.data.duration_in_text;
			})
		}
	},

	computed: {
		self() {
			return this;
		},

		isFormValid() {
			return this.assigneeIds.length > 0;
		}
	}
}
</script>
