<template>
	<article
		class="catalog-card"
		:class="{
			'catalog-card--finished': finished,
			'catalog-card--started': started,
			'catalog-card--full-version': full_only,
		}"
	>
		<a
			:href="'/courses/' + course.id"
			class="catalog-card__image-link"
			:data-course-id="course.id"
			data-url="/courses/"
			:data-started="started"
			@click.prevent="openPreviewPopup()"
		>
			<img
				:src="course.logo"
				:alt="course.id"
				class="catalog-card__image"
				loading="lazy"
			/>
			<div
				class="catalog-card__preview"
				:data-course-id="course.id"
				data-url="/courses/"
				:data-started="started"
			></div>
		</a>
		<div class="catalog-card__content">
			<h3 class="catalog-card__title">
				<a
					:href="'/courses/' + course.id"
					ref="courseTitle"
					class="catalog-card__title-link"
					:title="course.name"
				>
					{{ course.name }}
				</a>
			</h3>
			<div class="catalog-card__footer">
				<p v-if="finished" class="catalog-card__footer-text">
					{{ $t('deadlines.finished') }}
				</p>

				<p v-if="started" class="catalog-card__footer-text">
					<a
						:href="'/courses/' + course.id"
						class="catalog-card__footer-link"
					>
						{{ $t('deadlines.continue') }}
					</a>
				</p>

				<p v-if="full_only" class="catalog-card__footer-text">
					{{ $t('full_only') }}
				</p>

				<p
					v-if="!(finished || started || full_only)"
					class="catalog-card__footer-text"
				>
					<span class="mr-10">
						{{ duration(course.duration_minutes) }}
					</span>
					<template v-if="course.users_count">
						{{ $t('started') }} {{ course.users_count }}
					</template>
				</p>

				<button
					@click="wish ? rmWish() : addWish()"
					v-if="!full_only"
					class="catalog-card__add-btn"
					:class="{ 'is-active': wish }"
					aria-label="Добавить"
				>
					<svg width="15" height="19" viewBox="0 0 17 23" xmlns="http://www.w3.org/2000/svg">
						<path d="M9.16874 17.4144L8.5 16.8129L7.83126 17.4144L2.7331 22H2C1.44771 22 1 21.5523 1 21V3C1 1.89543 1.89543 1 3 1H14C15.1046 1 16 1.89543 16 3V21C16 21.5523 15.5523 22 15 22H14.2669L9.16874 17.4144Z" />
					</svg>
				</button>
			</div>
		</div>
	</article>
</template>

<script>
import axios from "axios";

export default {
	name: "CoursePreview",

	props: {
		course: Object,
		full_only: {
			type: Boolean,
			default: true,
		},
		finished: {
			type: Boolean,
			default: false,
		},
		started: {
			type: Boolean,
			default: false,
		},
		bookmark: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			wish: this.bookmark,
		};
	},

	methods: {
		addWish() {
			axios
				.request({
					response_type: "json",
					headers: {
						"X-Requested-With": "XMLHttpRequest",
						"X-CSRF-Token": document
							.querySelector('meta[name="csrf-token"]')
							.getAttribute("content"),
					},
					method: "POST",
					url: "/wishes",
					data: { course_id: this.course.id },
				})
				.then((response) => {
					this.wish = !this.wish;
				})
				.catch((errors) => {
					console.log(errors.response.data);
				});
		},

		rmWish() {
			axios
				.request({
					response_type: "json",
					headers: {
						"X-Requested-With": "XMLHttpRequest",
						"X-CSRF-Token": document
							.querySelector('meta[name="csrf-token"]')
							.getAttribute("content"),
					},
					method: "DELETE",
					url: "/wishes/" + this.course.id,
					data: { course_id: this.course.id },
				})
				.then((response) => {
					this.wish = !this.wish;
				})
				.catch((errors) => {
					console.log(errors.response.data);
				});
		},

		limitSizeTitle() {
			let text = this.$refs.courseTitle.textContent.trim();

			if (text.length > 60) {
				this.$refs.courseTitle.textContent = text.slice(0, 60) + "...";
			}
		},

		duration(minutes) {
			if (minutes < 60) {
				return minutes + this.$t('duration.min');
			} else {
				return Math.round(minutes / 60) + this.$t('duration.h');
			}
		},

		openPreviewPopup() {
			axios
				.request({
					response_type: "json",
					headers: {
						"X-Requested-With": "XMLHttpRequest",
						"X-CSRF-Token": document
							.querySelector('meta[name="csrf-token"]')
							.getAttribute("content"),
					},
					methods: "GET",
					url: `/courses/${this.course.id}`,
				})
				.then((response) => {
					this.$store.commit("set_data_course_preview_popup", response.data);
					this.$store.commit("trigger_course_preview_popup", true);
				})
				.catch((errors) => {
					// nothing do this
				});
		},
	},
};
</script>
