<template>
	<section
		class="catalog__section"
		:class="{ 'catalog__section--bg-action-light': newCourses }"
		v-if="selection.courses.length > 0"
	>
		<div
			class="catalog__section-header"
			:class="{ 'catalog__section-header--with-link': selection.url }"
		>
			<h2 class="catalog__title">
				<a
					v-if="selection.url"
					class="catalog__title-link"
					:href="selection.url"
				>
					{{ selection.title }}
				</a>
				<span v-else>{{ selection.title }}</span>
				<span v-if="selection.count" class="quantity">
					{{ selection.count }}
				</span>
			</h2>
			<p v-if="selection.url" class="catalog__right">
				<a class="link-btn link-btn--light-font" :href="selection.url">{{ t.topic_all_courses }}</a>
			</p>
		</div>
		<Carousel
			class="catalog__list"
			:itemsToShow="4"
			:itemsToScroll="4"
			snapAlign="start"
		>
			<Slide
				v-for="(course, index) in selection.courses.slice(0, 12)"
				:key="'selection-' + course.id + '-' + index"
				class="catalog__item"
			>
				<CoursePreview
					:course="course"
					:full_only="false"
					:finished="course.finished"
					:started="course.active"
					:bookmark="course.wished"
				/>
			</Slide>
			<template v-if="selection.courses.length > 4" #addons>
				<Navigation />
			</template>
		</Carousel>
	</section>
</template>

<script>
import CoursePreview from "./CoursePreview.vue";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import 'vue3-carousel/dist/carousel.css';

export default {
	name: "CoursesCatalogSelection",

	components: {
		Carousel,
		Slide,
		Navigation,
		CoursePreview,
	},

	props: {
		selection: Object,
		newCourses: { type: Boolean, default: false }
	},

	data() {
		return {
			loading: true,
			t: I18n.t.courses,
		};
	},
};
</script>
