<template>
	<div
		v-infinite-scroll="loadMore"
		infinite-scroll-disabled="loading"
		infinite-scroll-distance="500"
	>
		<template v-if="current_company !== 'demo-courses'">
			<CoursesCatalogPresetSelection
				v-if="presets.length > 0"
				:presets="presets"
				:newCourses="true"
			/>
		</template>

		<CoursesCatalogSelection
			v-if="current_company !== 'mega'"
			:selection="newest_courses"
			:newCourses="true"
		/>

		<CoursesCatalogSelection
			v-for="(selection, index) in filtered_topics"
			:key="'catalog-' + selection.id + '-' + index"
			:selection="selection"
		/>
	</div>
</template>

<script>
import CoursesCatalogPresetSelection from "./Catalog/PresetSelection.vue";
import CoursesCatalogSelection from "./Catalog/Selection.vue";
import Topic from "./Topic.vue";
import infiniteScroll from "meta-infinite-scroll";

export default {
	name: "CoursesCatalog",

	components: {
		CoursesCatalogPresetSelection,
		CoursesCatalogSelection,
		Topic,
	},

	directives: { infiniteScroll },

	props: {
		presets: Array,
		topics: Array,
		newest_courses: Object,
		current_company: {
			type: String,
			default: ''
		}
	},

	data() {
		return {
			data: [],
			сoursesCatalogName: "",
			loading: false,
			currentPage: 1,
			count: 2,
		};
	},

	computed: {
		filtered_topics() {
			return this.topics
				.filter((i) => i.courses.length > 5)
				.sort((a, b) => b.count - a.count)
				.slice(0, this.count);
		},
	},

	methods: {
		loadMore: function() {
			this.loading = true;

			setTimeout(() => {
				this.count += 3;
				this.loading = false;
			}, 50);
		},
	},
};
</script>
