<template>
	<div class="quiz-filling-answers">
		<div
			class="quiz-filling-answers__item"
			v-for="(answerGroup, key) in groupedAnswers"
			:key="'filling_answer_' + key"
			:data-position="key + 1"
		>
			<div class="quiz-filling-answers__header">
				<h3 class="quiz-filling-answers__title">
					{{ t.question_id + ": " + "{" + key + "}" }}
				</h3>
				<button
					class="link-btn link-btn--red quiz-answer-btn"
					type="button"
					@click="deleteAnswer(answerGroup)"
				>
					{{ t.delete_answer }}
				</button>
			</div>

			<div
				:class="
					'quiz-filling-answers__removable-field removable-field' +
					(filling_type != 'input' ? ' removable-field--with-checkbox' : '')
				"
				v-for="(answer, index) in answerGroup"
				:key="index"
			>
				<label class="labeled-field removable-field__field">
					<input
						v-model="answer.secondary_body"
						size="100"
						type="text"
						class="labeled-field__input"
						:placeholder="'Вариант ' + key"
					/>
				</label>
				<label
					v-if="filling_type != 'input'"
					class="checkbox removable-field__checkbox"
				>
					<input
						class="checkbox__input"
						type="checkbox"
						v-model="answer.correct"
						:id="index + '-' + key"
					/>
					<span :for="index + '-' + key" class="checkbox__label">
						{{ t.correct_answer }}
					</span>
				</label>
				<button
					@click="deleteVariation(answerGroup, answer)"
					class="remove-btn remove-btn--small remove-btn--red removable-field__remove-btn"
					type="button"
					aria-label="Удалить ответ"
				></button>
			</div>

			<div class="mt-5">
				<button class="link-btn" type="button" @click="createVariation(key)">
					{{ t.add_answer_variation }}
				</button>
			</div>
		</div>
	</div>

	<div class="mt-15" v-if="Object.keys(groupedAnswers).length < 6">
		<button class="link-btn" type="button" @click="createBlankAnswer()">
			{{ t.add_another_answer }}
		</button>
	</div>
</template>

<script>
export default {
	name: "QuizFillingAnswer",

	props: {
		quiz_answers: Array,
		filling_type: String,
		i18n: { type: Object, required: true },
	},

	data() {
		return {
			picked: false,
			answers: this.quiz_answers.sort((a, b) => a.pos - b.pos),
			groupedAnswers: [],
			t: this.i18n,
		};
	},

	created() {
		this.answers.map(function (a) {
			if (a.body) {
			} else {
				a.body = "q" + a.pos;
				return a;
			}
		});
		this.groupedAnswers = _.groupBy(this.quiz_answers, "body");
	},

	methods: {
		changeCorrectValue(key, answer) {
			this.groupedAnswers[key].map(function (a) {
				a.correct = false;
				return a;
			});
			answer.correct = true;
		},

		createBlankAnswer() {
			const answer = {
				body: "q" + (Object.keys(this.groupedAnswers).length + 1),
				correct: null,
				pos: Object.keys(this.groupedAnswers).length + 1,
				secondary_body: null,
			};
			this.answers.push(answer);
			this.groupedAnswers = _.groupBy(this.answers, "body");
		},

		createVariation(body) {
			const answer = {
				body: body,
				correct: null,
				pos: 1,
				secondary_body: null,
			};
			this.groupedAnswers[body].push(answer);
			this.answers.splice(1, 0, answer);
		},

		deleteVariation(answerGroup, answer) {
			answerGroup.splice(answerGroup.indexOf(answer), 1);
			this.answers.splice(this.answers.indexOf(answer), 1);
		},

		deleteAnswer(answerGroup) {
			let tmp_vue = this;
			this.answers.map(function (a) {
				if (a.body == answerGroup[0].body) {
					tmp_vue.answers.splice(tmp_vue.answers.indexOf(a), 1);
				}
			});
			this.groupedAnswers = _.groupBy(this.answers, "body");
		},

		addAnswer() {
			this.values.push({
				id: 3,
				type: "text",
				data: [],
			});
			this.valueInputs.push("");
		},

		addData(data, key) {
			if (this.valueInputs[key] != "") {
				data.push(this.valueInputs[key]);
				this.valueInputs[key] = "";
			}
		},
	},
};
</script>
