<template>
	<div class="quiz-picture-wrap mt-15">
		<div class="quiz-picture">
			<img :src="image" class="quiz-picture__image" />
			<svg
				class="quiz-picture__svg"
				id="quizSVGImage"
				@mousedown="startCircle"
			>
				<circle
					:cx="circle.x"
					:cy="circle.y"
					:r="circle.r"
					:fill="circle.color"
					:stroke="circle.stroke"
					stroke-width="4px"
					opacity="0.8"
					v-for="circle in circles"
				/>
				<circle
					:cx="circle.picture_shapes.x"
					:cy="circle.picture_shapes.y"
					:r="circle.picture_shapes.r"
					:fill="circle.picture_shapes.color"
					opacity="0"
					v-for="circle in answers.filter(
						(a) => a.picture_shapes && a.picture_shapes.type == 'circle'
					)"
				/>
				<rect
					:x="rect.picture_shapes.x"
					:y="rect.picture_shapes.y"
					:width="rect.picture_shapes.width"
					:height="rect.picture_shapes.height"
					:fill="rect.picture_shapes.color"
					opacity="0"
					v-for="rect in answers.filter(
						(a) => a.picture_shapes && a.picture_shapes.type == 'rect'
					)"
				/>
			</svg>
		</div>
	</div>
	<a
		v-if="circles.length"
		@click="resetCircles"
		class="regular-btn white-btn quiz-picture-answer-reset"
	>
		{{ circles.length > 1 ? "Сбросить ответы" : "Сбросить ответ" }}
	</a>
</template>

<script>
export default {
	name: 'QuizQuestionPicture',

	components: {},

	props: {
		question: Object,
	},

	data() {
		return {
			image: this.question.normal_picture_url,
			value: [],
			answers: this.question.answers,
			circles: [],
			rightShapes: [],
		};
	},

	computed: {},

	mounted() {
		console.log('QuizQuestionPicture mounted');
	},

	methods: {
		checkCircle(c) {
			let root = this;
			this.answers.forEach(function (a) {
				if (a.picture_shapes.type == "rect") {
					var x0, x1, x2, x3, h, w;
					var y0, y1, y2, y3;
					h = a.picture_shapes.height;
					w = a.picture_shapes.width;
					x0 = a.picture_shapes.x;
					y0 = a.picture_shapes.y;

					x1 = x0 + w;
					y1 = y0;
					x2 = x1;
					y2 = y1 + h;
					x3 = x0;
					y3 = y2;
					let x = c.x;
					let y = c.y;

					var xp = [x0, x1, x2, x3];
					var yp = [y0, y1, y2, y3];
					let z = root.inPoly(x, y, xp, yp);

					a.picture_shapes.selected = a.picture_shapes.selected || z != 0;
					c.shapesList.push(z);
				} else {
					let x0 = a.picture_shapes.x;
					let y0 = a.picture_shapes.y;
					let r = a.picture_shapes.r;

					let x = c.x;
					let y = c.y;

					let z = (x - x0) * (x - x0) + (y - y0) * (y - y0) <= r * r;

					a.picture_shapes.selected = a.picture_shapes.selected || z;
					c.shapesList.push(z);
				}
			});
		},

		inPoly(x, y, xp, yp) {
			var npol = xp.length;
			var j = npol - 1;
			var c = 0;
			var i = 0;
			for (i = 0; i < npol; i++) {
				if (
					((yp[i] <= y && y < yp[j]) || (yp[j] <= y && y < yp[i])) &&
					x > ((xp[j] - xp[i]) * (y - yp[i])) / (yp[j] - yp[i]) + xp[i]
				) {
					c = !c;
				}
				j = i;
			}
			return c;
		},

		startCircle(e) {
			var rect = document
				.getElementById('quizSVGImage')
				.getBoundingClientRect();
			var dotPositionY = e.clientY - rect.top;
			var dotPositionX = e.clientX - rect.left;

			let circle = {
				x: dotPositionX,
				y: dotPositionY,
				r: 10,
				color: '#0188d1',
				stroke: '#ade2ff',
				shapesList: [],
			};

			this.checkCircle(circle);

			this.circles.push(circle);
			this.$emit('set-answer', this.answers);
		},

		resetCircles() {
			this.circles = [];
		},
	}
};
</script>
