<script>
export default {
	name: 'LiveEventForm',
	template: `<slot v-bind="self" />`,

	props: {
		live_event: { type: Object, required: true }
	},

	data() {
		return {
			formElement: null,
			person_ids: {
				value: this.live_event.person_ids
			},
			start_time: {
				value: this.live_event.start_time
			},
			end_time: {
				value: this.live_event.end_time
			},
			event_type: {
				value: this.live_event.event_type
			},
			over: this.live_event['over?'],
			dropdowns: {},
			datepickers: {},
			t: I18n.t
		}
	},

	computed: {
		self: function() {
			return this;
		},
	},

	mounted() {
		this.formElement = document.querySelector('.j-live-event-form');

		if (!this.over) {
			this.initializeDropdowns();
			this.initializeDatepickers();
		}
	},

	methods: {
		changeEventType(event_type) {
			this.event_type.value = event_type;
		},

		initializeDropdowns() {
			this.dropdowns.people = new Dropdown(document.querySelector('div[data-dropdown-name="j-dropdown-person_ids"]'), (values) => {
				this.person_ids.value = this.parseDropdownValues(values);
			})

			this.dropdowns.start_time = new Dropdown(document.querySelector('div[data-dropdown-name="j-dropdown-start_time"]'), (values) => {
				this.start_time.value = this.parseDropdownValues(values)[0];
			})

			this.dropdowns.end_time = new Dropdown(document.querySelector('div[data-dropdown-name="j-dropdown-end_time"]'), (values) => {
				this.end_time.value = this.parseDropdownValues(values)[0];
			})
		},

		initializeDatepickers() {
			this.formElement.querySelectorAll('.j-datepicker').forEach((datepicker) => {
				let options = {
					field: datepicker,
					theme: 'pikaday-theme',
					firstDay: 1 * moment().startOf('week').format('E'),
					i18n: {
						months: _.map(moment.months(), capitalize),
						weekdays: _.map(moment.weekdays(), capitalize),
						weekdaysShort: _.map(moment.weekdaysShort(), capitalize) }
				};

				if (I18n.locale == 'ru') {
					_.extend(options.i18n, { previousMonth: 'Предыдущий месяц', nextMonth: 'Следующий месяц' });
				}

				this.datepickers[datepicker.dataset.model] = new Pikaday(options)
			})
		},

		parseDropdownValues(values) {
			var result = [];

			if (values.length > 0) {
				for (const value of values) {
					if (value !== undefined && value.id) {
						result.push(value.id);
					}
				}
			}

			return result;
		}
	},

	watch: {
		start_time: function(time) {
			if (time >= this.end_time.value && time != '24:00') {
				this.end_time.value = moment(time, 'H:mm').add(1, 'hour').format('HH:mm');
			} else if (time == '24:00') {
				this.end_time.value = time;
			}
			this.dropdowns.end_time.add_selected_values(this.end_time.value);
			this.dropdowns.end_time.activate();
		},

		end_time: function(time) {
			if (time < this.start_time.value && time != '00:00') {
				this.start_time.value = moment(time, 'H:mm').subtract(1, 'hour').format('HH:mm');
			} else if (time == '00:00') {
				this.start_time.value = time;
			}
			this.dropdowns.start_time.add_selected_values(this.start_time.value);
			this.dropdowns.start_time.activate();
		}
	}
}
</script>
