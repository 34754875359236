<template>
	<a :href="program.url" class="progress-item">
		<div class="progress-item__main">
		<div class="progress-item__right">
			<h3 class="progress-item__title">
				{{ program.name }}
				<sup v-if="['fast_courses', 'fast_competences'].includes(program.program_type)" class="flag">Быстрое</sup>
				<sup v-if="program.recommended" class="flag">Рекомендуемое</sup>
			</h3>
			<p class="progress-item__descr" v-html="program.program_description"></p>
			<p class="progress-item__descr mt-10">
				<span class="dark-font-color">{{$t('appointed_by')}}</span>: {{ program.author_name }}<template
					v-if="program.user_role == 'super_admin'">{{ $t('roles.super_admin') }}</template><template
					v-if="program.author_department_admin">, {{ $t('roles.department_admin') }}</template><template
					v-if="program.author_company_head">, {{ $t('roles.company_head') }}</template>
			</p>
			<p class="progress-item__descr">
				<span class="dark-font-color">{{$t('appointment_date')}}</span>: {{ formatAppointmentDate(program.created_at) }}
			</p>
			<div v-if="program.editor_url" class="mt-5">
				<a class="link-btn icon icon--small icon--edit small-font-size" :href="program.editor_url">Перейти в редактор курсов</a>
			</div>
		</div>
		<div v-show="hasProgress(program)" class="progress-item__left">
			<span v-if="programComplete(program)" class="icon icon--box icon--tiny icon--check icon--high-color"></span>
			<span v-else-if="program.is_onboarding">{{ program.count_completed_users_text }}</span>
			<span v-else>{{ program.progress_in_percent }}%</span>
		</div>
		</div>
		<div class="progress-item__progress">
			<div class="progress-item__scale" :class="'progress-item__scale--' + program.progress_status">
				<span class="progress-item__bar" :style="{ width: program.progress_in_percent + '%' }"></span>
			</div>
		</div>
	</a>
</template>

<script>
export default {
	name: 'ProgramItem',

	props: {
		program: { type: Object, required: true }
	},

	data() {
		return {}
	},

	methods: {
		programComplete(program) {
			return (program.progress_in_percent == 100 ? true : false);
		},

		hasProgress(program) {
			return program.is_onboarding ? program.count_completed_users > 0 : program.progress_in_percent > 0;
		},

		formatAppointmentDate(date) {
			const dateArray = date.split(/[\-\+ :T]/);

			return `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}`
		}
	},
}
</script>
