<template>
	<div v-if="isShow">
		<div class="end-time">
			<div class="page-inner">
				<div>
					<span class="bold-font">{{ $t('messages.end_time') }}</span>
				</div>
				<div class="end-time__counter">
					<div class="end-time__item">
						<div class="end-time__value">{{ days }}</div>
						<div class="end-time__descr">дни</div>
					</div>
					<div class="end-time__item">
						<div class="end-time__value">{{ hours }}</div>
						<div class="end-time__descr">часы</div>
					</div>
					<div class="end-time__item">
						<div class="end-time__value">{{ minutes }}</div>
						<div class="end-time__descr">минуты</div>
					</div>
					<div class="end-time__item">
						<div class="end-time__value">{{ seconds }}</div>
						<div class="end-time__descr">секунды</div>
					</div>
				</div>
				<a @click="sendEvent('https://eduson.academy/platform-special', 'request_full_course_buy_now')" class="regular-btn end-time-btn">Купить полный курс</a>
			</div>
		</div>

		<section v-if="isShowPopup" class="modal modal--counter showing j-popup">
			<div class="modal__card modal__card--small">
				<div class="modal__main">
					<h3 class="modal__title mb-10">Ваш пробный период подошёл к&nbsp;концу</h3>
					<p class="modal__text">
						Надеемся, вы успели получить полезные знания и навыки за эти 7 дней.
						Приглашаем вас продолжить обучение на нашей программе. Нажмите на кнопку, чтобы получить полный доступ к курсу.
					</p>
					<footer class="mt-20 text-center">
						<a @click="sendEvent('https://eduson.academy/platform-special', 'request_full_course_timer_end')" class="large-btn action-btn">Продолжить обучение</a>
					</footer>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import axios from "axios";

export default {
	name: 'Counter',

	props: {
		current_user_created_at: {
			type: String,
			required: true
		},
		counter_days: {
			type: Number,
			default: 7
		},
		start_action_day: {
			type: String,
			default: ''
		},
		current_company: {
			type: String,
			required: true
		},
		events_url: {
			type: String,
			required: true
		},
		current_user_id: {
			type: Number,
			required: false
		},
		current_company_id: {
			type: Number,
			required: false
		}
	},

	data() {
		return {
			MILLISECONDS_IN_DAY: 86400000,
			startActionDate: this.start_action_day ? this.getParseDate(this.start_action_day) : this.getParseDate(this.current_user_created_at),
			currentUserCreatedAt: this.getParseDate(this.current_user_created_at),
			endDate: '',
			counterDays: this.counter_days,
			isShow: true,
			isShowPopup: false,
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0
		}
	},

	created() {
		if (+this.currentUserCreatedAt.getTime() < +this.startActionDate.getTime()) {
			this.isShow = false;
			return;
		}

		this.endDate = +this.currentUserCreatedAt.getTime() + (this.counterDays * this.MILLISECONDS_IN_DAY);

		this.updateRemainingTime();

		if (this.getDifTime()) {
			setInterval(this.updateRemainingTime, 1000);
		}
	},

	methods: {
		getDifTime() {
			const currentTime = new Date();
			const difference = this.endDate - +currentTime.getTime();

			if (difference > 0) {
				return difference;
			}

			return false;
		},

		updateRemainingTime() {
			const difference = this.getDifTime();

			if (difference > 0) {
				this.days = Math.floor(difference / (1000 * 60 * 60 * 24));
				this.hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
				this.minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
				this.seconds = Math.floor((difference % (1000 * 60)) / 1000);
			} else {
				this.isShowPopup = true;
			}
		},

		getParseDate(stringOfDate) {
			const arr = stringOfDate.split(/[\-\+ :T]/);
			const date = new Date();

			date.setUTCFullYear(arr[0]);
			date.setUTCMonth(arr[1] - 1);
			date.setUTCDate(arr[2]);
			date.setUTCHours(arr[3]);
			date.setUTCMinutes(arr[4]);
			date.setUTCSeconds(arr[5]);

			return date;
		},

		sendEvent(redirect_url, event_name) {
			axios.request({
				headers: {
					"X-Requested-With": "XMLHttpRequest",
					"X-CSRF-Token": document
						.querySelector('meta[name="csrf-token"]')
						.getAttribute("content"),
				},
				url: this.events_url,
				method: "POST",
				data: {
					name: event_name,
					description: "Запрос из курса: " + this.current_company,
					user_id: this.current_user_id,
					eventable_type: "Company",
					eventable_id: this.current_company_id,
					redirect_url: redirect_url
				}
			}).then((response) => {
				window.location.href = redirect_url;
			})
		}
	}
}
</script>

<style>
.modal.modal--counter .modal__card {
	padding-top: 250px;
	background-image: url(../../../assets/images/counter/counter.png);
	background-position: top center;
	background-size: contain;
	background-repeat: no-repeat;
}

.modal.modal--counter .modal__main {
	background-color: #fff;
}

.end-time {
	background-color: #8e1191;
	color: #fff;
}

.end-time .page-inner {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 40px;
	padding-left: 60px;
}

.end-time__counter {
	position: relative;
	display: grid;
	grid-template-columns: repeat(4, 50px);
	padding: 5px 10px;
}

.end-time__counter::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #fff;
	transform: skew(-5deg, 0deg);
}

.end-time__item {
	position: relative;
	z-index: 1;
	padding-right: 5px;
}

.end-time__item:not(:last-child)::before {
	content: ":";
	position: absolute;
	top: 3px;
	right: 0;
	color: #8e1191;
}

.end-time__value {
	color: #8e1191;
	font-size: 20px;
	font-weight: 700;
	text-align: center;
}

.end-time__descr {
	color: #8e1191;
	font-size: 11px;
	text-align: center;
}

.end-time-btn {
	background-color: hsl(46, 91%, 78%);
	color: #212121;
	border-radius: 16px;
	font-weight: 700;
}

.end-time-btn:hover {
	background-color: hsl(46, 91%, 68%);
	color: #212121;
}
</style>
