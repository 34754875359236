<template>
	<section :class="[{ showing: showPopup }, 'modal']" @click.self="closePopup">
		<div class="modal__card modal__card--wide">
			<button v-if="isShowCloseBtn" @click="closePopup" class="remove-btn modal__close" aria-label="Закрыть"></button>
			<slot name="header"></slot>
			<slot></slot>
			<slot name="footer"></slot>
			<div v-if="loading" class='loader loader--block j-loading-action'><div class="loader__icon"></div></div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'BasePopup',

	props: {
		withCloseBtn: true
	},

	data() {
		return {
			showPopup: false,
			loading: false,
			isShowCloseBtn: this.withCloseBtn
		}
	},

	methods: {
		closePopup(event) {
			this.emitter.emit('close_popup');
			this.showPopup = false;
			return this;
		},

		openPopup() {
			this.showPopup = true;
			return this;
		},

		loadingOn() {
			this.loading = true;
			return this;
		},

		loadingOff() {
			this.loading = false;
			return this;
		},

		showCloseBtn() {
			this.isShowCloseBtn = true;
			return this;
		},

		hideCloseBtn() {
			this.isShowCloseBtn = false;
			return this;
		}
	},
}
</script>
