<template>
	<ul class="test-card__list">
		<li v-for="(val, key, index) in values" class="test-card__item">
			<label class="radio-button radio-button--bordered">
				<input
					:id="question.pos + '-' + key"
					:name="question.pos + _uid"
					type="radio"
					:value="key"
					v-model="value"
					v-on:change="$emit('set-answer', [val])"
					class="radio-button__input"
				>
				<span :for="question.pos + '-' + key" class="radio-button__label" v-html="val.body"></span>
			</label>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'QuizQuestionRadio',

	props: {
		question: Object
	},

	data() {
		return {
			value: '',
			values: this.randomAnswers()
		}
	},

	methods: {
		randomAnswers(){
			return this.question.answers.sort(this.compareRandom)
		},

		compareRandom(a, b) {
			return Math.random() - 0.5;
		}
	},

	watch: {
		question: function(newVal, oldVal) {
			this.values = newVal.answers
		}
	},
}
</script>
