<template>
	<div class="page-card event">
		<section class="event__sidebar event-sidebar scrollable-shadow">
			<h2 class="visually-hidden"></h2>
			<div class="event-sidebar__inner">
				<template v-if="eventsExist(allLiveEvents)">
					<section class="event-sidebar__section" v-if="eventsExist(upcomingLiveEvents)">
						<h3 class="event-sidebar__title">{{ t.live_events.closest }}</h3>
						<a class="event-sidebar__link" :class="[isCurrentEvent(liveEvent) ? 'is-selected' : '']" v-for="liveEvent in upcomingLiveEvents" :key="liveEvent.id" @click="loadLiveEvent(liveEvent)">
							<div class="event-sidebar__date">
								{{ dateToDayAndMonthFormat(liveEvent.date) }}
								<span class="event-sidebar__date-day">({{ dateToWeekFormat(liveEvent.date) }})</span>
							</div>
							<div class="event-sidebar__name">{{ liveEvent.title }}</div>
						</a>
					</section>

					<section class="event-sidebar__section" v-if="eventsExist(pastLiveEvents)">
						<h3 class="event-sidebar__title">{{ t.live_events.past }}</h3>
						<a class="event-sidebar__link" :class="[isCurrentEvent(liveEvent) ? 'is-selected' : '']" v-for="liveEvent in pastLiveEvents" :key="liveEvent.id" @click="loadLiveEvent(liveEvent)">
							<div class="event-sidebar__date">
								{{ dateToDayAndMonthFormat(liveEvent.date) }}
								<span class="event-sidebar__date-day">({{ dateToWeekFormat(liveEvent.date) }})</span>
							</div>
							<div class="event-sidebar__name">{{ liveEvent.title }}</div>
						</a>
					</section>
				</template>
				<template v-else>
					<div class="event-sidebar__title" v-if="!this.loadingOn">
						{{ t.live_events.no_planned }}
					</div>
				</template>
			</div>
		</section>

		<section class="event__main">
			<div v-if="this.loadingOn" class='loader loader--block'><div class='loader__icon'></div></div>
			<live-event-show v-if="this.currentLiveEvent" :live_event="this.currentLiveEvent" :admin="this.can_edit"></live-event-show>
		</section>
	</div>
</template>

<script>
import axios from 'axios';
import LiveEventShow from './Show.vue';

export default {
	name: 'LiveEventList',

	components: {
		LiveEventShow
	},

	props: {
		live_events_url: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			upcomingLiveEvents: [],
			pastLiveEvents: [],
			allLiveEvents: [],
			currentLiveEvent: null,
			loadingOn: false,
			can_edit: false,
			t: I18n.t
		}
	},

	mounted() {
		this.loadLiveEvents();
	},

	methods: {
		isCurrentEvent(live_event) {
			return this.currentLiveEvent && live_event.id === this.currentLiveEvent.id;
		},

		loadLiveEvent(live_event) {
			this.currentLiveEvent = live_event;
			this.loadingOn = true;

			axios({
				response_type: 'json',
				headers: { 'X-Requested-With': 'XMLHttpRequest' },
				method: 'GET',
				url: live_event.live_event_url
			}).then((response) => {
				this.currentLiveEvent = response.data;
				this.can_edit = this.currentLiveEvent.can_edit;
				this.currentLiveEventId = this.currentLiveEvent.id;
				this.changeBrowserUrl(this.currentLiveEvent.id, this.currentLiveEvent.live_events_list_url);
				this.loadingOn = false;
			})

			return this.currentLiveEvent;
		},

		loadLiveEvents() {
			axios({
				response_type: 'json',
				headers: { 'X-Requested-With': 'XMLHttpRequest' },
				method: 'GET',
				url: this.live_events_url
			}).then((response) => {
				this.upcomingLiveEvents = response.data.upcoming_live_events;
				this.pastLiveEvents = response.data.past_live_events;
				this.allLiveEvents = this.upcomingLiveEvents.concat(this.pastLiveEvents);
				this.currentLiveEvent = this.loadLiveEvent(this.allLiveEvents.find(live_event => live_event.id === response.data.current_live_event_id));
				this.changeBrowserUrl(this.currentLiveEvent.id, this.live_events_url);
			})
		},

		eventsExist(events) {
			return (events && events.length > 0) ? true : false;
		},

		changeBrowserUrl(live_event_id, url) {
		 window.history.replaceState({ live_event_id: live_event_id }, null, `${url}?format=html`);
		},

		dateToDayAndMonthFormat(date) {
			return moment(date).format('D MMMM');
		},

		dateToWeekFormat(date) {
			return moment(date).format('dd');
		}
	},
}
</script>
