<template>
	<article
		class="progress-item progress-item--download j-completed-course"
		:class="{ 'j-no-diploma': !course.course_with_diploma }"
	>
		<div class="progress-item__right">
			<div class="progress-item__type mb-3">
				{{ $t(`course_types.${course.course_type}`) }}
			</div>
			<h3 class="progress-item__title">
				<a :href="'/courses/' + course.course_id">
					{{ course.course_safe_name }}
				</a>
			</h3>
		</div>
		<div class="progress-item__left">
			<template v-if="course.course_with_diploma">
				<a
					v-if="firstName"
					:href="'/courses/' + course.course_id + '/diploma?diploma_id=' + course.id"
					class="small-btn white-btn no-print j-cabinet-user-diploma"
				>
					{{ $t('buttons.download_diploma') }}
				</a>
				<span v-else class="small-btn disabled-btn no-print">
					{{ $t('buttons.download_diploma') }}
				</span>
			</template>
		</div>
	</article>
</template>

<script>
export default {
	name: 'CompletedCourseItem',

	props: {
		course: { type: Object },
		firstName: { type: String }
	},
}
</script>
