<template>
	<section class="catalog__section catalog__section--bg-action">
		<div class="catalog__section-header catalog__section-header--with-link">
			<h2 class="catalog__title">
				<a class="catalog__title-link" href="/presets">{{ t.presets }}</a>
				<span class="quantity">{{ presets.length }}</span>
			</h2>
			<div class="catalog__right">
				<a href="/presets" class="link-btn link-btn--light-font">
					{{ t.all_presets }}
				</a>
			</div>
		</div>
		<carousel
			class="catalog__image-list"
			:itemsToShow="3"
			:itemsToScroll="3"
			snapAlign="start"
		>
			<slide v-for="(preset, index) in presets" :key="'presetSection-' + preset.id + '-' + index" class="catalog__image-item">
				<div class="image-item">
					<a :href="'/presets/' + preset.id" class="image-item__link">
						<img
							:src="preset.picture_url"
							:alt="preset.name"
							class="image-item__img"
							loading="lazy"
						/>
					</a>
				</div>
			</slide>
			<template v-if="presets.length > 3" #addons>
				<Navigation />
			</template>
		</carousel>
	</section>
</template>

<script>
import { Carousel, Slide, Navigation } from "vue3-carousel";
import 'vue3-carousel/dist/carousel.css';

export default {
	name: "PresetSelection",

	components: {
		Carousel,
		Slide,
		Navigation
	},

	props: {
		presets: Array,
		newCourses: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			loading: true,
			t: I18n.t.courses
		};
	},
};
</script>
