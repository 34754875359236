<template>
	<div class="catalog">
		<div
			class="catalog__header catalog__header--with-link"
			:class="{
				'catalog__header--bg-action': preset,
				'catalog__header--bg-demo': !preset && !search,
				'catalog__header--bg-start': search,
			}"
		>
			<h1 class="catalog__title">{{ topic.title }}</h1>
			<p class="catalog__right">
				<a v-if="preset" href="/presets" class="link-btn link-btn--light-font">
					{{ $t('buttons.all_presets') }}
				</a>
				<a
					v-if="topic.with_playlist"
					:href="topic.player_url"
					class="regular-btn white-btn play-list-btn"
				>
					{{ $t('buttons.start_playlist') }}
				</a>
			</p>
		</div>

		<section
			v-if="beginnerCourses.length && !preset && !search"
			class="catalog__section catalog__section--bg-demo catalog__section--no-padding-top"
		>
			<div class="catalog__section-header">
				<h2 class="catalog__title">{{ $t("where_to_begin") }}</h2>
			</div>
			<Carousel
				class="catalog__list"
				:itemsToShow="4"
				:itemsToScroll="4"
				snapAlign="start"
			>
				<Slide
					v-for="(course, index) in beginnerCourses"
					:key="'beginerCourse-' + course.id + '-' + index"
					class="catalog__item"
				>
					<CoursePreview
						:course="course"
						:full_only="false"
						:finished="course.finished"
						:started="course.active"
						:bookmark="course.wished"
					/>
				</Slide>
				<template v-if="beginnerCourses.length > 4" #addons><Navigation /></template>
			</Carousel>
		</section>

		<section class="catalog__section">
			<div class="catalog__section-header catalog__section-header--with-filtres">
				<h2 class="catalog__title">
					{{ $t('buttons.all_on_topic') }}
					<span class="quantity">{{ filteredCourses.length }}</span>
				</h2>
				<div class="catalog__filtres courses-filters">
					<section class="courses-filters__group courses-filters__group--select">
						<button class="courses-filters__btn" type="button">{{ sortingTitle }}</button>
						<div class="courses-filters__holder">
							<label v-for="(sortingItem, index) in sorting" :key="'sorting-' + index" class="course-select">
								<input
									class="course-select__input"
									type="radio"
									:value="sortingItem.value"
									v-model="sortingModel"
								>
								<span class="course-select__label">{{ sortingItem.title }}</span>
							</label>
						</div>
					</section>
					<section class="courses-filters__group courses-filters__group--filters ml-15">
						<button class="regular-btn action-btn filter-btn" type="button">
							{{ $t('filters') }}
						</button>
						<div class="courses-filters__holder">
							<section class="courses-filters__section">
								<h3 class="courses-filters__title">{{ $t('types_of_courses') }}</h3>
								<ul class="courses-filters__list">
									<li
										v-for="(type, index) in courseTypes"
										:key="'topicFilter-' + index"
										class="courses-filters__item"
									>
										<label class="checkbox">
											<input
												type="checkbox"
												class="checkbox__input"
												:value="type"
												v-model="coursesTypesModel"
											/>
											<span class="checkbox__label">
												{{ $t(`course_types.${type}`) }}
											</span>
										</label>
									</li>
								</ul>
							</section>

							<section class="courses-filters__section">
								<h3 class="courses-filters__title">{{ $t('subjects') }}</h3>
								<ul class="courses-filters__list">
									<li
										v-for="(subject, index) in subjects"
										:key="'subject-' + index"
										class="courses-filters__item"
									>
										<label class="checkbox">
											<input
												type="checkbox"
												class="checkbox__input"
												:value="subject"
												v-model="subjectsModel"
											/>
											<span class="checkbox__label">
												{{ subject }}
											</span>
										</label>
									</li>
								</ul>
							</section>
							<section class="courses-filters__section">
								<label class="courses-radiobutton">
									<input
										class="courses-radiobutton__input"
										type="checkbox"
										value="completed_courses"
										v-model="completedCoursesModel"
									>
									<span class="courses-radiobutton__label">{{ $t('hide_completed_courses') }}</span>
								</label>
							</section>
							<div class="courses-filters__footer">
								<p class="light-font-color small-font-size">
									{{ $t('found')}}: {{ filteredCourses.length }}
								</p>
								<button class="regular-btn red-btn ml-auto" @click="clearFilters" type="button">{{ $t('buttons.clear_filters') }}</button>
							</div>
						</div>
					</section>
				</div>
			</div>
			<div class="catalog__list mt-10">
				<div
					v-for="(course, index) in filteredCourses"
					:key="'course-' + topic.id + '-' + index"
					class="catalog__item"
				>
					<CoursePreview
						:course="course"
						:full_only="false"
						:finished="course.finished"
						:started="course.active"
						:bookmark="course.wished"
					/>
				</div>
			</div>

			<div class="catalog__message" v-if="filteredCourses.length === 0">
				<p class="catalog__message-text">
					{{ $t('messages.no_filters_message') }}
				</p>
			</div>
		</section>
	</div>
</template>

<script>
import CoursePreview from "./Catalog/CoursePreview.vue";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import 'vue3-carousel/dist/carousel.css';

export default {
	name: 'Topic',

	components: {
		Carousel,
		Slide,
		Navigation,
		CoursePreview,
	},

	props: {
		topic: Object,
		preset: { type: Boolean, default: false },
		search: { type: Boolean, default: false }
	},

	data() {
		return {
			сoursesCatalogName: '',
			loading: true,
			showFiltres: false,
			sortOrdered: false,
			coursesTypesModel: [],
			subjectsModel: [],
			sortingModel: 'popular',
			completedCoursesModel: '',
			showLoader: false,
			sorting: [
				{ title: this.$t('sorting.popular'), value: 'popular'},
				{ title: this.$t('sorting.new_first'), value: 'order'},
				{ title: this.$t('sorting.sorting_AZ'), value: 'sortingAZ'},
				{ title: this.$t('sorting.sorting_ZA'), value: 'sortingZA'},
			]
		};
	},

	computed: {
		coursesFilteredByType() {
			return this.coursesTypesModel.length
			? this.topic.courses.filter((course) =>
				this.coursesTypesModel.includes(course.type)
			)
			: this.topic.courses;
		},

		beginnerCourses() {
			return this.topic.courses.filter((course) => course.beginner);
		},

		subjects() {
			return [...new Set(this.topic.courses.flatMap((course) => course.course_tags))];
		},

		courseTypes() {
			return [...new Set(this.topic.courses.map((course) => course.type))];
		},

		sortingTitle() {
			return this.sorting.filter((item) => item.value === this.sortingModel).map(obj => obj.title)[0];
		},

		filteredCourses() {
			let courses = this.topic.courses;

			if (this.coursesTypesModel.length !== 0) {
				courses = courses.filter(
					(course) => this.coursesTypesModel.includes(course.type)
				)
			}

			if (this.subjectsModel.length !== 0) {
				courses = courses.filter(course =>
					course.course_tags.some(tag => this.subjectsModel.includes(tag))
				)
			}

			if (this.sortingModel === 'order') {
				courses = courses.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
			}

			if (this.sortingModel === 'sortingAZ') {
				courses = courses.sort((a, b) => a.name.localeCompare(b.name));
			}

			if (this.sortingModel === 'sortingZA') {
				courses = courses.sort((a, b) => b.name.localeCompare(a.name));
			}

			if (this.sortingModel === 'popular') {
				courses = courses.sort((a, b) => b.users_count - a.users_count);
			}

			if (this.completedCoursesModel) {
				courses = courses.filter((course) => !course.finished);
			}

			return courses;
		},
	},

	methods: {
		clearFilters() {
			this.subjectsModel = [];
			this.coursesTypesModel = [];
			this.completedCoursesModel = '';
		}
	},
};
</script>

