<template>
	<div>
		<p class="small-sub-title-font-size bold-font">Ваш ответ:</p>
		<draggable
			class="quiz-drag-list is-numbered mt-10"
			:list="answers"
			group="people"
			v-bind="dragOptions"
			@change="$emit('set-answer', answers)"
		>
			<transition-group type="transition" class="quiz-drag-transition quiz-drag-transition--grid">
				<div
					class="quiz-drag-box"
					v-for="(val, key) in answers" :data-position="key + 1 + '.'"
					:key="val.pos"
				>
					<div class="quiz-drag-item">
						<div class="quiz-drag-item-main">
							<p class="bold-font">{{ val.body }}</p>
						</div>
					</div>
				</div>
			</transition-group>
		</draggable>
	</div>

	<div class="hide">
		<p class="small-sub-title-font-size sub-light-font-color bold-font">Доступные варианты:</p>
		<draggable
			class="quiz-drag-list mt-10"
			:list="values"
			group="people"
			v-bind="dragOptions"
		>
			<transition-group type="transition" class="quiz-drag-transition quiz-drag-transition--grid">
				<div
					class="quiz-drag-box"
					v-for="(val, key) in values"
					:key="val.pos"
				>
					<div class="quiz-drag-item">
						<div class="quiz-drag-item-main">
							<p class="bold-font">{{ val.body }}</p>
						</div>
					</div>
				</div>
			</transition-group>
		</draggable>
	</div>
</template>

<script>
export default {
	name: 'QuizQuestionSorting',

	props: {
		question: Object
	},

	data() {
		return {
			value: [],
			answers: this.randomAnswers(),
			values: []
		}
	},

	computed: {
		dragOptions() {
			return {
				animation: 200,
				group: 'description',
				disabled: false,
				ghostClass: 'ghost'
			};
		}
	},

	methods: {
		randomAnswers(){
			return this.question.answers.sort(this.compareRandom)
		},

		compareRandom(a, b) {
			return Math.random() - 0.5;
		}
	},
}
</script>
