<script>
import axios from "axios";

export default {
	name: "EditorQuizForm",
	template: `<slot v-bind="self" />`,

	props: {
		path: String,
		course: Object,
		lecture: Object,
		assessment: Object,
		questions: Array,
	},

	data() {
		return {
			dragging: false,
			assessmentQuestions: this.questions,
			questionCoverField: false,
			quizTimeField: true,
			quizNumberField: true,
			quizFinishTextField: false,
			quizCustomPassingScoreField: false,
		};
	},

	mounted() {
		this.emitter.on("close_popup", this.getAssessmentQuestions);
		this.quizTimeField = this.assessment.time_limit > 0;
		this.quizNumberField = this.assessment.sample_size > 0;
		this.quizFinishTextField = this.assessment.finish_text.length > 0;
		this.quizCustomPassingScoreField = this.assessment.custom_passing_score > 0;
	},

	watch: {
		quizTimeField(val, val2) {
			if (!val) this.assessment.time_limit = 0;
		},

		quizNumberField(val, val2) {
			if (!val) this.assessment.sample_size = 0;
		},
	},

	computed: {
		self() {
			return this;
		},

		dragOptions() {
			return {
				animation: 200,
				group: "description",
				disabled: false,
				ghostClass: "ghost",
			};
		},
	},

	methods: {
		getAssessmentQuestions() {
			var url = this.path;
			this.question = {};
			axios
				.request({
					response_type: "json",
					headers: { "X-Requested-With": "XMLHttpRequest" },
					url: url,
					method: "GET",
				})
				.then((response) => {
					this.assessmentQuestions = response.data.questions;
				});
		},

		setQuestionPos(evt) {
			var assessmentQuestions = this.assessmentQuestions;
			var doubles = this.assessmentQuestions.map(function(num) {
				num.pos = assessmentQuestions.indexOf(num) + 1;
				return num;
			});

			axios
				.request({
					responseType: "json",
					headers: {
						"X-Requested-With": "XMLHttpRequest",
						"X-CSRF-Token": document
							.querySelector('meta[name="csrf-token"]')
							.getAttribute("content"),
					},
					method: "patch",
					url: this.path + "/update_questions_position",
					data: {
						questions: doubles,
					},
				})
				.then((response) => {
					console.log(response);
				})
				.catch((error) => {
					console.log(error);
				});
		},

		showEditorQuizQuestionFormPopup(url, id) {
			this.emitter.emit("show_quiz_question_form", url + id + "/edit");
			setTimeout(
				show_popup(
					this.getAssessmentQuestions("/editor/courses/" + this.course.id)
				),
				1000
			);
		},

		newEditorQuizQuestionFormPopup(url, id) {
			this.emitter.emit("show_quiz_question_form", url);
			setTimeout(
				show_popup(
					this.getAssessmentQuestions("/editor/courses/" + this.course.id)
				),
				1000
			);
		},
	},
};
</script>
