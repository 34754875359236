<template>
	<section class="welcome-section welcome-section--sales" id="sales">
		<div class="page-inner">
			<div class="welcome-section__header">
				<h2 class="welcome-section__title">{{ $t('sales_courses.title') }}</h2>
				<p v-html="$t('sales_courses.sub_title')" class="welcome-section__subtitle"></p>
			</div>
		</div>
		<div class="sales-type-slider-wrapper">
			<div class="page-inner">
				<div class="sales-type-slider-holder">
					<Carousel class="sales-type-slider">
						<Slide class="sales-type-slide">
							<div class="sales-type-item">
								<div>
									<h3 class="sales-type-title">{{ $t('sales_courses.cold_call.title') }}</h3>
									<div v-html="$t('sales_courses.cold_call.description')"></div>
								</div>
								<div>
									<img v-if="currentLocale === 'ru'" :src="img_1" class="sales-type-image" alt="">
									<img v-else :src="img_2" class="sales-type-image" alt="">
								</div>
							</div>
						</Slide>
						<Slide class="sales-type-slide">
							<div class="sales-type-item">
								<div>
									<h3 class="sales-type-title">{{ $t('sales_courses.video_rehearsal.title') }}</h3>
									<div v-html="$t('sales_courses.video_rehearsal.description')"></div>
								</div>
								<div>
									<img v-if="currentLocale === 'ru'" :src="img_3" class="sales-type-image" alt="">
									<img v-else :src="img_4" class="sales-type-image" alt="">
								</div>
							</div>
						</Slide>
						<Slide class="sales-type-slide">
							<div class="sales-type-item">
								<div>
									<h3 class="sales-type-title">{{ $t('sales_courses.speech_recognition.title') }}</h3>
									<div v-html="$t('sales_courses.speech_recognition.description')"></div>
								</div>
								<div>
									<img v-if="currentLocale === 'ru'" :src="img_5" class="sales-type-image" alt="">
									<img v-else :src="img_6" class="sales-type-image" alt="">
								</div>
							</div>
						</Slide>
						<template #addons>
							<Navigation />
						</template>
					</Carousel>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
	name: 'WelcomeEduson',

	components: {
		Carousel,
		Slide,
		Navigation,
	},

	props: {
		img_1: String,
		img_2: String,
		img_3: String,
		img_4: String,
		img_5: String,
		img_6: String
	},

	data() {
		return {
			currentLocale: window.currentLocale
		}
	}
}
</script>
