<template>
	<div class="questionnaires__question question-card">
		<input type="hidden" :name="'questionnaire[questions_attributes][' + question_index + '][_destroy]'" v-model="question._destroy">
		<input type="hidden" :name="'questionnaire[questions_attributes][' + question_index + '][id]'" v-model="question.id">
		<input type="hidden" :name="'questionnaire[questions_attributes][' + question_index + '][pos]'" v-model="question.pos">
		<div class="question-card__row">
			<label class="labeled-field question-card__main-field">
				<input
					class="labeled-field__input"
					type="text"
					:name="'questionnaire[questions_attributes][' + question_index + '][title]'"
					:placeholder="t.title.placeholder + ' ' + (question_index + 1)"
					v-model.trim="question.title"
					@input="debouncedQuestionUpdate(question)"
				>
			</label>
			<label class="select-wrapper question-card__submain-field">
				<select class="select-input" v-model="question.question_type" @change="debouncedQuestionUpdate(question)">
					<option value="one">{{ t.question_type.one }}</option>
					<option value="many">{{ t.question_type.many }}</option>
				</select>
			</label>
		</div>

		<ul class="question-card__answers-list">
			<QuestionnairesAnswer
				v-for="(answer, answer_index) in question.answers"
				:key="'question.' + question_index + '.answer.' + answer_index"
				:answer="answer"
				:question_index="question_index"
				:answer_index="answer_index"
				:is_question_with_single_answer="isQuestionWithSingleAnswer(question)"
			/>
		</ul>

		<div class="question-card__footer">
			<div class="question-card__footer-left">
				<button class="link-btn" type="button" @click.prevent="addAnswer(question)" :disabled="isQuestionTitleEmpty(question) || !isSynced">
					{{ t.btns.add_answer }}
				</button>
				<span class="sub-light-font-color">/</span>
				<button class="link-btn" type="button" @click.prevent="addAnswer(question, 'open')" :disabled="isQuestionTitleEmpty(question) || alreadyAddOpenAnswer(question)">
					{{ t.btns.add_other }}
				</button>
			</div>
			<div class="question-card__footer-right">
				<label class="checkbox">
					<input
						type="checkbox"
						class="checkbox__input"
						:name="'questionnaire[questions_attributes][' + question_index + '][required]'"
						v-model="question.required"
						@input="debouncedQuestionUpdate(question)"
						:disabled="isQuestionTitleEmpty(question)"
						>
						<span class="checkbox__label">{{ t.required_field }}</span>
				</label>
				<button class="link-btn link-btn--red ml-25" type="button" @click.prevent="removeQuestion(question, question_index)">
					{{ t.btns.remove }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import { debounce } from 'vue-debounce';
import QuestionnairesAnswer from './Answer.vue';

export default {
	name: 'QuestionnairesQuestion',

	components: { QuestionnairesAnswer },

	props: {
		question: { type: Object, required: true },
		question_index: { type: Number, required: true }
	},

	data() {
		return {
			state: '',
			t: I18n.t.questionnaire_dashboard_form.fields.questionnaire.questions
		}
	},

	methods: {
		isQuestionTitleEmpty(question) {
			return !question.title || question.title.length <= 0;
		},

		alreadyAddOpenAnswer(question) {
			return question.answers ? question.answers.filter(this.activeAnswer).some(answer => answer.answer_type == 'open') : false;
		},

		isQuestionWithSingleAnswer(question) {
			return question.question_type == 'one';
		},

		isSynced() {
			return this.state == 'synced';
		},

		activeAnswer(answer) {
			return !answer._destroy;
		},

		questionFieldUpdate(question) {
			this.state = 'modified';
			this.debouncedQuestionUpdate(question);
		},

		debouncedQuestionUpdate: debounce(function(question) {
			this.updateQuestion(question);
		}, 800),

		updateQuestion(question) {
			axios({
				response_type: "json",
				headers: {
					"X-Requested-With": "XMLHttpRequest",
					"X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
				},
				method: 'PATCH',
				url: question.update_url,
				data: { question }
			}).then(response => {
				this.state = 'synced'
			}).catch(errors => {
				this.state = 'error'
			})
		},

		removeQuestion(question, index) {
			question._destroy = true

			axios({
				response_type: "json",
				headers: {
					"X-Requested-With": "XMLHttpRequest",
					"X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
				},
				method: 'DELETE',
				url: question.update_url,
				data: {}
			}).then(response => {
				this.state = 'synced';
			}).catch(errors => {
				question._destroy = false;
				this.state = 'error';
			})
		},

		addAnswer(question, answer_type = 'default') {
			let answer = { ...this.answerPlaceholder };
			answer.answer_type = answer_type;
			question.answers = question.answers.filter(this.activeAnswer);

			if (answer_type == 'open') {
				answer.title = 'Другое...';
				answer.pos = 9999;
				question.answers.splice(question.answers.length, 0, answer);
			} else {
				answer.pos = question.answers.filter(a => !this.isOpenAnswer(a)).length + 1;
				if (this.alreadyAddOpenAnswer(question)) {
					question.answers.splice(-1, 0, answer);
				} else {
					question.answers.push(answer);
				}
			}

			axios({
				response_type: "json",
				headers: {
					"X-Requested-With": "XMLHttpRequest",
					"X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
				},
				method: 'POST',
				url: question.create_answer_url,
				data: { answer }
			}).then(response => {
				answer.id = response.data.answer.id;
				answer.update_url = response.data.answer.update_url;
				this.state = 'synced';
			}).catch(errors => {
				this.state = 'error';
			})
		},

		activeAnswer(answer) {
			return !answer._destroy;
		},

		isOpenAnswer(answer) {
			return answer.answer_type == 'open';
		},
	},

	computed: {
		sortedAnswers() {
			return this.question.answers.filter(a => !a._destroy).sort((a, b) => a.pos < b.pos);
		}
	},
}
</script>
